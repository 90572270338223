import logo from "Assets/svg/plaexLogo.svg";
import axiosInstance from "api/axios";
import { useEffect, useRef, useState,useContext } from "react";
import { LanguageContext } from "context/LanguageContext";

const PasswordConfirm = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [error, setError] = useState("");
  const [succesMsg, setSucessMsg] = useState("");
  const errRef = useRef();
  const {language} = useContext(LanguageContext)
  //const userRef = useRef();

  const validatePassword = () => {
    if (password !== confirmPassword) {
      setErrMsg("Passwords don't match");
    } else {
      setErrMsg("");
    }
  };
  useEffect(() => {
    validatePassword();
    setErrMsg("");
  }, [password, confirmPassword]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");
    const user = {
      password: password,
      token: token,
    };
    try {
      await axiosInstance.post("user/password_reset/confirm/", user, {
        withCredentials: true,
      });
      setPassword("");
      setConfirmPassword("");
      setSucessMsg("Password changed successfully");
      localStorage.clear();
      window.location.href = "/login";
    } catch (err) {
      if (err.response?.status === 404) {
        setErrMsg("Token Expired");
        console.log("Password: ", err);
      }
      if (err.response?.status === 400) {
        setErrMsg("Password not valid");
      }
      //setErrMsg("Password not Valid ");
      console.log("Password: ", err);
      errRef.current.focus();
    }
  };
  return (
    <div className="bg-white">
      <div className="fixed top-0 z-50 w-full px-4 py-4 bg-white">
        <nav className="px-3 py-2 lg:px-24 lg:pl-6">
          <div className="flex justify-between">
            <div className="flex items-center justify-start">
              <a href="/" className="flex w-16 ml-2 md:mr-24">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <div className="flex items-center gap-x-4">
              <a href="/login">
                <button className="text-md font-bold hover:underline decoration-green-600 underline-offset-8 decoration-4 ">
                  Login
                </button>
              </a>
            </div>
          </div>
        </nav>
      </div>
      <div className="relative z-10 flex items-center justify-center ">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 flex items-center justify-center h-screen ">
          <div className="mx-auto max-w-lg rounded-lg shadow-lg p-4 bg-slate-100 sm:p-6 lg:p-8">
            <h1 className="text-center text-2xl font-bold  sm:text-3xl">
            {language === "Dutch" ? "Wachtwoord Opnieuw Instellen" : "Reset Password"}
            </h1>
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscrean"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <p
              ref={errRef}
              className={succesMsg ? "successmsg" : "offscrean"}
              aria-live="assertive"
            >
              {succesMsg}
            </p>

            <form
              className="mt-6 mb-0 space-y-4  p-4  sm:p-6 lg:p-8"
              onSubmit={handleSubmit}
            >
              <label className="text-sm" htmlFor="password">
                {language === "Dutch" ? "Wachtwoord": "Password"}
              </label>
              <input
                className="w-full rounded-lg border-2 p-3 pr-12 text-sm shadow-sm focus:border-green-500 focus:outline-none"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={validatePassword}
                required
              />
              <label
                className="text-sm"
                htmlFor="confirm-password"
                style={{ display: "inline-block" }}
              >
                {language === "Dutch" ? "Bevestig Wachtwoord": "Confirm Password"}
              </label>
              <input
                className="w-full rounded-lg border-2 p-3 pr-12 text-sm shadow-sm focus:border-green-500 focus:outline-none"
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onBlur={validatePassword}
                required
              />
              <button
                type="submit"
                className="block w-full rounded-lg bg-green-600 px-5 py-3 text-sm text-white font-medium"
              >
                {language === "Dutch" ? "Wachtwoord Opnieuw Instellen": "RESET PASSWORD"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordConfirm;
