import axiosInstance from "api/axios";
import Hamburger from "common/Components/Hamburger/Hamburger";
import Navbar from "common/Components/NavBar/NavBar";
import Sidebar from "common/Components/SideBar/SideBar";
import {useEffect, useState, useContext, useRef} from "react";
import {GrDocumentUpdate} from "react-icons/gr";
import {PiUploadSimpleLight} from "react-icons/pi";
import {LanguageContext} from "context/LanguageContext";
import PropTypes from "prop-types";
import {PhotoIcon, UserCircleIcon} from "@heroicons/react/24/solid";

const Profile = () => {
    const [profiledata, setProfileData] = useState({
        user_id: null,
        email: "",
        first_name: "",
        last_name: "",
        user_profile: "",
        company: "",
        phone_number: "",
        kvk_number: "",
    });
    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState("");
    const {language} = useContext(LanguageContext);
    const [loading, setLoading] = useState(true); // Initial loading state set to true


    const fileInputReference = useRef(null);
    const handleFileUpload = () => {
        fileInputReference.current.click();
    };

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);
        setImageName(selectedImage.name);
    };

    const handlePost = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("user_profile", image);
        formData.append("first_name", profiledata.first_name);
        formData.append("last_name", profiledata.last_name);
        formData.append("phone_number", profiledata.phone_number);

        try {
            const csrfToken = document.cookie.split("; ")
                .find(row => row.startsWith("csrftoken="))
                ?.split("=")[1];

            const response = await axiosInstance.put("/update", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-CSRFToken": csrfToken,
                },
                withCredentials: true,
            });
            setProfileData({...profiledata, ...response.data});
            //setShowModal(false);
            window.location.reload();
        } catch (error) {
            console.error("Error updating profile:", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get("");

                let updatedUserProfile = response.data.user_profile;

                // Replace http with https for non-local environments
                if (
                    !response.config.baseURL.startsWith("http://127.0.0.1") &&
                    !response.config.baseURL.startsWith("http://localhost")
                ) {
                    updatedUserProfile = updatedUserProfile.replace(
                        /^http:\/\//i,
                        "https://",
                    );
                }

                setProfileData({
                    ...profiledata,
                    ...response.data,
                    user_profile: updatedUserProfile,
                });
            } catch (error) {
                console.error("Error fetching profile data:", error);
            } finally {
                setLoading(false); // Set loading to false regardless of success or failure
            }
        };

        fetchData();
    }, []);


    return (
        <div className="min-h-[100vh] dark:bg-plaex-dark flex flex-col justify-start">
            <Navbar/>

            <section className="flex flex-grow justify-center items-center">
                {loading ? (
                    <ProfileCardSkeleton/>
                ) : (
                    <ProfileCard
                        handlePost={handlePost}
                        profiledata={profiledata}
                        handleImageChange={handleImageChange}
                        handleFileUpload={handleFileUpload}
                        fileInputReference={fileInputReference}
                        imageName={imageName}
                    />
                )}
            </section>
        </div>
    );
};

const LoadingSkeleton = () => (
    <div
        className="p-10 bg-white shadow-lg relative flex flex-col min-w-0 break-words w-full mb-6 pb-10 rounded-lg mt-20 animate-pulse">
        <div className="w-full h-40 flex flex-row justify-between items-start">
            <div className="relative h-40 w-40 rounded-full bg-gray-300"></div>
        </div>

        <div className="w-full h-full mt-4">
            <div className="rounded-full h-8 bg-gray-300 w-1/2 mb-2"></div>
            <div className="rounded-full h-6 bg-gray-300 w-1/3 mb-1"></div>
            <div className="rounded-full h-4 bg-gray-300 w-1/4"></div>
        </div>
    </div>
);

const ProfileCardSkeleton = () => (
    <div className="p-10 bg-white shadow-lg relative flex flex-col min-w-0 break-words w-full mb-6 pb-10 rounded-lg mt-20 animate-pulse">
        <div className="space-y-12">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 pl-4">
                <div>
                    <div className="h-6 bg-gray-300 rounded-full w-24 mb-4"></div>
                </div>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                    <div className="sm:col-span-3">
                        <div className="h-4 bg-gray-300 rounded-full w-28 mb-2"></div>
                        <div className="h-8 bg-gray-300 rounded-md w-full"></div>
                    </div>

                    <div className="sm:col-span-3">
                        <div className="h-4 bg-gray-300 rounded-full w-28 mb-2"></div>
                        <div className="h-8 bg-gray-300 rounded-md w-full"></div>
                    </div>


                    <div className="sm:col-span-4">
                        <div className="h-4 bg-gray-300 rounded-full w-28 mb-2"></div>
                        <div className="h-8 bg-gray-300 rounded-md w-full"></div>
                    </div>

                    <div className="sm:col-span-4">
                        <div className="h-4 bg-gray-300 rounded-full w-28 mb-2"></div>
                        <div className="h-8 bg-gray-300 rounded-md w-full"></div>
                    </div>

                    <div className="sm:col-span-4">
                        <div className="h-4 bg-gray-300 rounded-full w-28 mb-2"></div>
                        <div className="h-8 bg-gray-300 rounded-md w-full"></div>
                    </div>

                    <div className="sm:col-span-4">
                        <div className="h-4 bg-gray-300 rounded-full w-28 mb-2"></div>
                        <div className="h-8 bg-gray-300 rounded-md w-full"></div>
                    </div>

                    <div className="col-span-full">
                        <div className="h-4 bg-gray-300 rounded-full w-28 mb-2"></div>
                        <div className="mt-2 flex items-center gap-x-3">
                            <div className="w-12 h-13 bg-gray-300 rounded-full"></div>
                            <div className="h-8 bg-gray-300 rounded-md w-20"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6 pr-16">
            <div className="h-8 bg-gray-300 rounded-md w-16"></div>
            <div className="h-8 bg-gray-300 rounded-md w-20"></div>
        </div>
    </div>
);


const ProfileCard = ({
                         handlePost,
                         profiledata,
                         handleImageChange,
                         handleFileUpload,
                         fileInputReference,
                         imageName,
                     }) => {
    return (
        <form onSubmit={handlePost}>
            <div className="space-y-12">
                <div
                    className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 pl-4">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Profile
                        </h2>
                    </div>

                    {/* Todo: Fix the focus color to match plaex color palette*/}
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 ">
                        <div className="sm:col-span-3">
                            <label
                                htmlFor="first-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                First name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="first-name"
                                    name="first-name"
                                    type="text"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={`${profiledata.first_name}`}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label
                                htmlFor="last-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Last name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="last-name"
                                    name="last-name"
                                    type="text"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={`${profiledata.last_name}`}

                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={`${profiledata.email}`}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label
                                htmlFor="company"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Company
                            </label>
                            <div className="mt-2">
                                <input
                                    id="company"
                                    name="company"
                                    type="text"
                                    autoComplete="company"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={`${profiledata.company}`}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label
                                htmlFor="phone"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Phone number
                            </label>
                            <div className="mt-2">
                                <input
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    autoComplete="company"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={`${profiledata.phone_number}`}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label
                                htmlFor="kvk"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                KVK number
                            </label>
                            <div className="mt-2">
                                <input
                                    id="kvk"
                                    name="kvk"
                                    type="text"
                                    autoComplete="kvk"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    value={`${profiledata.kvk_number}`}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className="col-span-full">
                            <label
                                htmlFor="photo"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Photo
                            </label>
                            <div className="mt-2 flex items-center gap-x-3">
                                <img
                                    alt="Profile Picture"
                                    src={`${profiledata.user_profile}`}
                                    className="w-12 h-12 rounded-full object-cover"
                                />
                                <button
                                    type="button"
                                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    onClick={handleFileUpload}
                                >
                                    Change
                                </button>
                                <input
                                    className="hidden"
                                    type="file"
                                    ref={fileInputReference}
                                    accept="image/*"
                                    onChange={(e) => handleImageChange(e)}
                                />


                            </div>
                            <span className="mt-4 float-left mr-2 text-plaex-dark text-xs font-light w-60">
            {imageName}
          </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6 pr-16">
                <button
                    type="button"
                    className="text-sm font-semibold leading-6 text-gray-900"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Save
                </button>
            </div>
        </form>
    );
}


ProfileCard.propTypes = {
    handlePost: PropTypes.func.isRequired,
    profiledata: PropTypes.shape({
        user_profile: PropTypes.string,
        company: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string,
    }).isRequired,
    handleImageChange: PropTypes.func.isRequired,
    handleFileUpload: PropTypes.func.isRequired,
    fileInputReference: PropTypes.shape({current: PropTypes.instanceOf(Element)}).isRequired,
    imageName: PropTypes.string.isRequired,
};

export default Profile;
