import React, {useState, useEffect, useContext} from 'react'
import axiosInstance from 'api/axios';
import hamburgermenu from "Assets/svg/hamburgermenu.svg"
import close from "Assets/svg/close.svg"
import carbondioxide from "Assets/svg/carbon-dioxide.svg"
import carbondioxidewhite from "Assets/svg/carbondioxidewhite.svg"
import {MdOutlineEnergySavingsLeaf} from "react-icons/md"
import {BiSolidCategory} from 'react-icons/bi'
import { AiOutlineCaretDown, AiOutlineCaretLeft } from "react-icons/ai";
import {CgProfile} from "react-icons/cg"
import {RiHistoryFill} from "react-icons/ri"
import {RiDeleteBin7Line} from "react-icons/ri"
import {ImHome3} from "react-icons/im"
import {MdOutlineLogout} from "react-icons/md"
import {RiLockPasswordLine} from 'react-icons/ri'
import './Hamburger.css'
import { LanguageContext } from 'context/LanguageContext';
import { GiGreenhouse } from 'react-icons/gi';
import { FaWeightHanging } from 'react-icons/fa';

const Hamburger = (props) => {

    const [isOpen, setIsOpen] = useState(true);
    const [bins, setBins] = useState([]);
    const [mainDash, setMainDash] = useState("");
    const [weightscale, setWeightScale] = useState("");
    const [binDash, setBinDash] = useState("");
    const [wasteDash, setWasteDash] = useState("");
    const [CO2Dash, setCO2Dash] = useState("");
    const [subclassDash,setSubClassDash] = useState("")
    const [prof, setProfile] = useState("");
    const [binId, setBinId] = useState("");
    const [ishamburgeropen, sethamburgeropen] = useState(false);
    const [selectedButton, setSelectedButton] = useState('');

    const[textmaindash, setTextMainDash] = useState("")
    const [textbindash, setTextBinDash] = useState("")
    const [textwastedash, setTextWasteDash] = useState("")
    const [textprofiledash, setTextProfileDash] = useState("")
    const [textco2dash,setTextCO2Dash] = useState("")
    const[textsubclassdash,setSubClassTextDash] = useState("");
    const [textweightscale, setWeightScaleText] = useState("");
    const [hovered, setHovered] = useState(false);
    const {language} = useContext(LanguageContext)
    const [tier, setTier] = useState('Basic')
    const [isweightscale, setisWeightScale]  = useState(false)

    useEffect(() => {
    axiosInstance
        .get("/get_bins")
        .then((response) => {
        setBins(response.data);
        setBinId(response.data[0].unique_identifier);
        const hasWeightScale = response.data.some(item => item.is_weight_scale);
        setisWeightScale(hasWeightScale);
        })
        .catch((error) => {
        console.error(error);
        });
    }, []);

    useEffect(() => {
        axiosInstance
            .get("/usertier")
            .then((response) => {
                setTier(response.data.tier)
            })
            .catch((error) => {
            console.error(error);
            });
        }, []);

    useEffect(() => {
    if (window.location.pathname === "/") {
        setMainDash("bg-green-600");
        setTextMainDash("text-white")
    } else if (window.location.pathname === "/bin-dashboard") {
        setBinDash("bg-green-600");
        setTextBinDash("text-white")
    } else if (window.location.pathname.includes("waste-dashboard")) {
        setWasteDash("bg-green-600");
        setTextWasteDash("text-white")
    } else if (window.location.pathname === "/profile") {
        setProfile("bg-green-600");
        setTextProfileDash("text-white")
    }
    else if(window.location.pathname.includes("/Savings")){
        setCO2Dash("bg-green-600")
        setTextCO2Dash("text-white")
    }
    else if(window.location.pathname.includes("/SubclassDisplay")){
        setSubClassDash("bg-green-600")
        setSubClassTextDash("text-white")
    }
    else if(window.location.pathname.includes("/weightscale")){
        setWeightScale("bg-green-600")
    }
    }, []);

    const handleBinClick = (bin, bin_ident) => {
    props.onBinClick(bin);
    setSelectedButton(bin_ident);
    };

    const handleLogout = () => {
    try {
        axiosInstance.post("/user/logout", {
        withCredentials: true,
        });
        localStorage.removeItem("loggedin");
        window.location.href = "/login";
    } catch (e) {
        console.log("logout not working", e);
    }
    };

    const handleHamburgerClick = () => {
        sethamburgeropen(true)
      };

      const handleCloseClick = ()=>{
        sethamburgeropen(false)
      }

  return (
    <div>
        <aside className='fixed flex flex-col px-1 top-[82px] justify-center items-center sm:z-20 xs:z-20 lg:z-0 md:z-0 left-0  md:w-60  dark:bg-plaex-dark  ml-1 transition-all duration-300 border-none cursor-pointer h-12 sidebar'>
            <div className="overflow-y-auto flex flex-col justify-between flex-grow">
                <ul className='sm:block lg:hidden md:hidden xs:block flex ml-4'>
                    <li className='relative items-center  space-x-3 ml-3 mt-4 pr-6'>
                        {ishamburgeropen ? <img class="ml-2 w-7 h-7 object-cover" src={close} alt="Close" onClick={()=> handleCloseClick()} />
                        :
                        <img class="ml-2 w-7 h-7 object-cover " src={hamburgermenu} alt="HamburgerMenu" onClick={()=>handleHamburgerClick()}/>}
                    </li>
                </ul>
            </div>
        </aside>

        {/* <aside className={`fixed flex flex-col px-1 lg:top-24 xs:top-28 sm:top-28 md:top-24 left-0 ${ishamburgeropen ? 'sm:flex xs:flex Slide ' : 'SlideOut sm:invisible xs:invisible sm:opacity-0 xs:opacity-0 ' */}
        <aside className={`fixed flex flex-col px-1 lg:top-24 xs:top-28 sm:top-28 md:top-24 left-0 ${ishamburgeropen ? 'sm:flex xs:flex' : 'sm:invisible xs:invisible sm:opacity-0 xs:opacity-0 '
            } lg:flex md:flex lg:visible lg:opacity-100 md:visible md:opacity-100 md:w-60 bg-white dark:bg-plaex-dark h-full transition-all duration-300 border-none z-10 sidebar`}>
            <div className="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">


            <ul className="flex flex-col py-4 space-y-1">
                <li className={`hover:text-white active:text-white ${textmaindash} `}>
                    <a
                        href="/"
                        className={`hover:bg-green-600 ${mainDash} focus:bg-green-600  focus:bg-opacity-50 relative flex flex-row items-center h-11 space-x-4  rounded-3xl pr-6 border-l-4 border-transparent`}
                        tabindex="0"
                    >
                        {/* <img class="ml-2 dark:invert" src={home} alt="Home" />
                         */}
                         <ImHome3 className="ml-[5px] text-2xl"/>

                        <span class="ml-2 text-sm tracking-wide truncate">
                        Dashboard
                        </span>
                    </a>
                </li>


            {/* weight scale */}
            {/* {isweightscale!=false && (<li>
              <a
                href="/weightscale"
                className={`hover:bg-green-600 ${weightscale} focus:bg-green-600 hover:text-white focus:bg-opacity-50 relative flex flex-row items-center h-11 space-x-3  rounded-3xl pr-6 border-l-4 border-transparent`}
                tabindex="0"
              >
                <FaWeightHanging className='ml-2 text-xl dark:invert'/>

                <span class="ml-2 text-sm tracking-wide truncate ">
                  Weight Scale
                </span>
              </a>
            </li>)} */}

                <li className={`hover:text-white active:text-white ${textprofiledash} `}>
                    <a
                        href="/profile"
                        className={`hover:bg-green-600 ${prof} focus:bg-green-600  focus:bg-opacity-50 relative flex flex-row items-center h-11 space-x-4 rounded-3xl pr-6 border-l-4 border-transparent`}
                        tabindex="0"
                    >
                        {/* <img class="ml-2 dark:invert" src={profile} alt="Profile" /> */}
                        <CgProfile className="ml-1 text-2xl"/>

                        <span class="ml-2 text-sm tracking-wide truncate">{language === "Dutch" ? "Profiel":"Profile"}</span>
                    </a>
                </li>

                {  tier === "Business" || tier === "Mid" ?  (<div className={`hover:bg-green-600 active:text-white ${textbindash} hover:text-white ${binDash} focus:bg-green-600  focus:bg-opacity-50 relative flex flex-row items-center h-11  rounded-3xl pr-6 border-l-4 border-transparent`}>
                    <a
                        href="/bin-dashboard"
                        className=" hover:bg-green-600  focus:bg-green-600 focus:bg-opacity-50  relative flex flex-row items-center h-11 space-x-3 rounded-3xl pr-6 border-l-4 border-transparent"
                        tabindex="0"
                    >
                        {/* <img
                        class="  dark:invert"
                        style={{ marginLeft: "0.30rem" }}
                        src={bin}
                        alt="bin"
                        /> */}
                        <RiDeleteBin7Line className="text-2xl "/>

                        <span class="ml-2 pl-1 text-sm tracking-wide truncate">
                        {language === "Dutch" ? "Afval Vergelijking" :"Waste Comparison"}
                        </span>
                    </a>

                    <div className="relative flex flex-col items-center">
                        <button onClick={() => setIsOpen((prev) => !prev)}>
                        {!isOpen ? (
                            <AiOutlineCaretDown className="text-base" />
                        ) : (
                            <AiOutlineCaretLeft className="text-base" />
                        )}
                        </button>
                    </div>

                </div>
                ) : <></>}

                {!isOpen && (
                <div className="">
                    {bins.map((bin) => (
                    <div>
                        <button
                        onClick={() => handleBinClick(bin, bin.unique_identifier)}
                        className={`uppercase flex flex-row items-center hover:bg-green-100 focus:outline-none focus:bg-green-200 w-14 md:w-52 h-11 space-x-3 rounded-3xl ml-4 pr-6 border-l-4 border-transparent ${selectedButton === bin.unique_identifier ? 'selected' : ''} justify-center items-center`}
                        >
                        {bin.unique_identifier}
                        </button>
                    </div>
                    ))}
                </div>
                )}

                <li className={`hover:text-white active:text-white ${textwastedash}`}>
                <a
                    href={`/waste-dashboard/${binId}/organic`}
                    className={`hover:bg-green-600 hover:text-white  ${wasteDash} focus:bg-green-600  focus:bg-opacity-50 relative flex flex-row items-center h-11 space-x-2  rounded-3xl pr-6 border-l-4 border-transparent`}
                    tabindex="0"
                >
                    <RiHistoryFill className="hover:text-white ml-1 text-2xl"/>

                    <span class="ml-2 pl-2 text-sm tracking-wide truncate">
                    {language === "Dutch" ?  "Individuele Stroom" : "Individual Stream"}
                    </span>
                </a>
                </li>

                {  tier === "Business" && (<li className={`hover:text-white active:text-white ${textco2dash}`}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}>
                <a
                    href={`/Savings/${binId}/all`}
                    className={`hover:bg-green-600 ${CO2Dash} focus:bg-green-600  focus:bg-opacity-50 relative flex flex-row items-center h-11 space-x-2  rounded-3xl pr-6 border-l-4 border-transparent`}
                    tabindex="0"
                >
                    {/* <img
                    class="ml-1 dark:invert h-7 w-7"
                    src={carbondioxide}
                    alt="C02 Savings"
                    /> */}
                    {/* <MdOutlineEnergySavingsLeaf className='ml-1 text-2xl'/> */}
                    {
                        <img 
                        src= {hovered || textco2dash === "text-white" ? carbondioxidewhite : carbondioxide}
                        alt="carbon dioxide" 
                        className="ml-1 h-7 w-7"
                      />
                    }


                    <span class="ml-2 pl-2 text-sm tracking-wide truncate">
                     {language === "Dutch" ? "Besparingen" : "Savings"}
                    </span>
                </a>
                </li>
            )}


                {tier === "Business" && (<li className={`hover:text-white active:text-white ${textsubclassdash}`}>
                <a
                    href={`/SubclassDisplay/${binId}/`}
                    className={`hover:bg-green-600 hover:text-white  ${subclassDash} focus:bg-green-600  focus:bg-opacity-50 relative flex flex-row items-center h-11 space-x-2  rounded-3xl pr-6 border-l-4 border-transparent`}
                    tabindex="0"
                >
                    <BiSolidCategory className="hover:text-white ml-1 text-2xl"/>

                    <span class="ml-2 pl-2 text-sm tracking-wide truncate">
                    {language === "Dutch" ?  "Subklassen Weergave" : "Subclass Display"}
                    </span>
                </a>
                </li>
                )}


            {/* {tier === "Business" && (<li className={`hover:text-white active:text-white ${textsubclassdash}`}>
                <a
                    href={`/ghgdashboard/${binId}/`}
                    className={`hover:bg-green-600 hover:text-white  ${subclassDash} focus:bg-green-600  focus:bg-opacity-50 relative flex flex-row items-center h-11 space-x-2  rounded-3xl pr-6 border-l-4 border-transparent`}
                    tabindex="0"
                >
                    <GiGreenhouse className="hover:text-white ml-1 text-2xl"/>

                    <span class="ml-2 pl-2 text-sm tracking-wide truncate">
                    {language === "Dutch" ?  "Broeikasgas Dashboard" : "GHG Dashboard"}
                    </span>
                </a>
                </li>
                )} */}
            </ul>

            <ul className="flex flex-col inset-x-4 lg:mb-24 md:mb-24 sm:mb-32 xs:mb-32 space-y-1 text-sm">
                {/* 
                <li>
                <a
                    href="#"
                    className=" hover:bg-green-600  focus:bg-green-600 focus:bg-opacity-50 relative flex flex-row items-center h-11 space-x-3 rounded-3xl pr-6 border-l-4 border-transparent"
                    tabindex="0"
                >
                    <img class="ml-2 dark:invert" src={Settings} alt="Settings" />

                    <span class="ml-2 text-sm tracking-wide truncate">
                    Settings
                    </span>
                </a>
                </li>
                <li>
                <a
                    href="#"
                    className=" hover:bg-green-600  focus:bg-green-600 focus:bg-opacity-50 relative flex flex-row items-center h-11 space-x-3 rounded-3xl pr-6 border-l-4 border-transparent"
                    tabindex="0"
                >
                    <img class="ml-2 dark:invert" src={Help} alt="Help" />

                    <span class="ml-2 text-sm tracking-wide truncate">Help</span>
                </a>
                </li>
                */}
                <li className='hover:text-white active:text-white'>
                <a
                        href="/changepassword"
                        className={`hover:bg-green-600 ${prof} focus:bg-green-600  focus:bg-opacity-50 relative flex flex-row items-center h-11 space-x-4 rounded-3xl pr-6 border-l-4 border-transparent`}
                        tabindex="0"
                    >
                    {/* <img class="ml-2 dark:invert" src={Signout} alt="Sign Out" /> */}
                    <RiLockPasswordLine className="ml-1 hover:text-white text-2xl font-semibold"/>

                    <span class="ml-2 text-sm tracking-wide truncate">
                    {language === "Dutch" ? "Verander Wachtwoord": "Change Password"}
                    </span>
                </a>
                </li>

                <li className='hover:text-white active:text-white'>
                <a
                    href="#"
                    className=" hover:bg-green-600 hover:text-white  focus:bg-green-600 focus:bg-opacity-50 relative flex flex-row items-center h-11 space-x-3 rounded-3xl pr-6 border-l-4 border-transparent"
                    tabindex="0"
                    onClick={handleLogout}
                >
                    {/* <img class="ml-2 dark:invert" src={Signout} alt="Sign Out" /> */}
                    <MdOutlineLogout className="ml-1 hover:text-white text-2xl font-semibold"/>

                    <span class="ml-2 text-sm tracking-wide truncate">
                    {language === "Dutch" ? "Afmelden": "Sign Out"}
                    </span>
                </a>
                </li>
                <li className="hidden md:block ml-2  text-xs text-slate-600">
                <span>@ 2023 PLAEX</span>
                </li>
            </ul>
            </div>
        </aside>
    </div>
  )
}

export default Hamburger
