import axiosInstance from "api/axios";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
  LogarithmicScale
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { AiFillDatabase } from "react-icons/ai";
import {AiTwotoneCalendar} from "react-icons/ai"
import "../Components/FillLevelChart.css";
import CustomCalendar from "common/Components/Calendar/Calendar";
import "./TotalWasteCount.css"
import {AiOutlineClose} from "react-icons/ai"
import { LanguageContext } from 'context/LanguageContext';
import {RiDeleteBin7Line} from 'react-icons/ri'
import * as XLSX from 'xlsx';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import html2canvas from 'html2canvas';
import { CSSTransition } from 'react-transition-group';
import {AiFillInfoCircle} from 'react-icons/ai'


const TotalWasteCount = (props) => {
  const { selectedBin } = props;
  const [waste, setWaste] = useState([]);
  const [selectedValue, setSelectedValue] = useState("all");
  const [bin_identifier, setBinIdentifier] = useState('')
  const [calendarclick, setCalendarClick] = useState(false);
  const [tier, setTier] = useState('')
  const { language } = useContext(LanguageContext);
  const [selectedFormat, setSelectedFormat] = useState('xlsx');
  const [barThickness, setBarThickness] = useState(50);
  const chartRef = useRef();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalclick, setModalClick] = useState(false)


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [datevalue, setDateValue] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);


  const handleCloseCalendar = () => {
    setCalendarClick(false);
  }

  const handleDateValueChange = (newDateValue) => {
    setDateValue(newDateValue);
    setCalendarClick(false)
  }

  useEffect(() => {
    axiosInstance
        .get("/usertier")
        .then((response) => {
            setTier(response.data.tier)
        })
        .catch((error) => {
        console.error(error);
        });
    }, []);

  useEffect(() => {
    ChartJS.register(
      BarElement,
      Tooltip,
      Legend,
      CategoryScale,
      LinearScale,
      ChartDataLabels,
      LogarithmicScale,
    );
  }, []);

  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleCalendarClick = () =>{
    setCalendarClick(!calendarclick);
  }
  const handleModalClick = () =>{
    setModalClick(false);
    setModalIsOpen(false)
  }

  useEffect(() => {
    async function fetchFillLevel() {
      let data = null;
      let bin_identifier = null;
      const startDate = new Date(datevalue[0].startDate)
      const endDate = new Date(datevalue[0].endDate)
      const params = {
        'startDate': startDate.toString(),
        'endDate': endDate.toString(),
      };
      const response = await axiosInstance.get(`gettotalweight/${selectedBin.id}`, { params: params });
      data = response.data.data;
      bin_identifier = response.data.bin_identifier;
      setWaste(data);
      setBinIdentifier(bin_identifier);
    }
  
    fetchFillLevel();
  }, [selectedBin, datevalue]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setBarThickness(30);
      } else {
        setBarThickness(50);
      }
    }
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  if (waste.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center text-gray-300 ">
        <div className="flex flex-col justify-center items-center p-10">
          <h3 className="text-gray-400">{language === "Dutch" ? "Geen FillLevel-gegevens" :  "No FillLevel Data"}</h3>
          <AiFillDatabase className="text-5xl" />
        </div>
      </div>
    );
  }

  let barPercentage = 0.9;
let categoryPercentage = 0.8;

const translateToDutch = (text) => {
  const daysInDutch = {
    "Mon": "Din",
    "Tue": "Woe",
    "Wed": "Don",
    "Thu": "Vri",
    "Fri": "Zat",
    "Sat": "Zon",
    "Sun": "Maa",
    "Today":"Vandaag",
    "organic": "Biologisch",
    "PMD" : "PMD",
    "paper" :"Papier",
    "residual" : "Resterend",
    "rest" : "Resterend",
    "all" : "alle",
  };
  const monthsInDutch = {
    "Mar": "Mrt",
    "May": "Mei",
    "Oct": "Okt"
  };
  let translatedText = text;
  for (const [key, value] of Object.entries(daysInDutch)) {
    translatedText = translatedText.replace(key, value);
  }
  for (const [key, value] of Object.entries(monthsInDutch)) {
    translatedText = translatedText.replace(key, value);
  }
  return translatedText;
}


const wasteData = {
  labels: waste.map((x) => language === 'Dutch' ? translateToDutch(x.label) : x.label),
  datasets: [
    {
      label: "Total Weight",
      data: waste.map((x) => x.total_weight), // Ensure this matches the API response
      backgroundColor: ["#004800"],
      datalabels: {
        color: "white",
      },
      borderRadius: 25,
      barThickness: barThickness,
      maxBarThickness: 70,
    },
  ],
};


  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        beginAtZero: true,
        type: 'logarithmic',
        grid: {
          display: false,
          // drawOnChartArea: false,
        },
        // innerHeight: 30,

        // to hide yaxis
        ticks: {
          font: {
            size: 0,
          },

          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },

        border: {
          display: false,
        },
      },

      x: {
        grid: {
          display: false,
        },
        // to hide xaxis
        ticks: {
          maxRotation: 90,
          minRotation: 90,
          font: {
            size: 11,
            weight: 600,
          },
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },

        border: {
          display: false,
        },
      },
    },

    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: false,
        padding: 10,
      },
      datalabels: {
        display: true,
        font: {
          size: 13,
        },
        formatter: (value) => {
          if (value === 0) {
            return null;
          }
          return value;
        },
        align: "start",
        anchor: "end",
      },
    },
  };
  
  const handleExporttoExcel = (export_type) => {
    if (export_type === 'xlsx') {
      exportToExcel(wasteData);
    } else if (export_type === 'csv') {
      exportToCSV(wasteData);
    } else if (export_type === 'png') { 
      exportChartAsPNG();
    }
  }
  

  const exportToExcel = (wasteData) => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    let streamvalue = language === "Dutch" ? "Total Gewicht" : "Total Weight"
    let wastevalue = language === "Dutch" ? translateToDutch(selectedValue) : selectedValue;
    const data = wasteData.labels.map((label, index) => {
      return wasteData.datasets.reduce((obj, dataset) => {
        let Date = language === "Dutch" ? "Datum": "Date";
        obj[Date] = label;
        obj[streamvalue] = dataset.data[index];
        return obj;
      }, {});
    });
  
    // Convert the data to worksheet
    const ws = XLSX.utils.json_to_sheet(data);
  
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
  
    // Write the workbook to a file
    XLSX.writeFile(wb, `${streamvalue}_${wastevalue}_${formattedDate}.xlsx`);
  };
  
  const exportToCSV = (wasteData) => {
  const streamvalue = language === 'Dutch' ? 'Totaal Gewicht' : 'TotalWeight';
  const wastevalue = language === 'Dutch' ? translateToDutch(selectedValue) : selectedValue;
  const date = new Date();
  const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  const fileName = `${streamvalue}_${wastevalue}_${formattedDate}.csv`;

  // Prepare CSV data
  const csvData = [];
  const headerRow = ['Date', streamvalue]; // Modify headers as needed
  csvData.push(headerRow);

  wasteData.labels.forEach((label, index) => {
    const rowData = [label, wasteData.datasets[0].data[index]]; // Modify data fields as needed
    csvData.push(rowData);
  });

  // Convert data to CSV string
  const csvContent = csvData.map((row) => row.join(',')).join('\n');
  
  // Create a Blob and trigger a download
  const blob = new Blob([csvContent], { type: 'text/csv' });
  if (window.navigator.msSaveBlob) {
    // For IE
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    // For modern browsers
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const exportChartAsPNG = () => {

  const chartSection = document.getElementById('fillLevelChart');

  html2canvas(chartSection).then(canvas => {

    const image = canvas.toDataURL('image/png');

    // Generate filename
    const streamvalue = language === 'Dutch' ? 'Totaal Gewicht' : 'TotalWeight';
    const wastevalue = language === 'Dutch' ? translateToDutch(selectedValue) : selectedValue;
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
    const filename = `${streamvalue}_${wastevalue}_${date}.png`;

    // Pass filename to save function
    saveDataURLToFile(image, filename);

  });

};
const saveDataURLToFile = (dataURL, filename) => {

  // convert data URL to blob
  const blob = dataURLToBlob(dataURL);  

  // create object URL from blob 
  const url = URL.createObjectURL(blob);

  // create anchor tag
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;

  // click to download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

};

const dataURLToBlob = (dataURL) => {
  // Convert base64 to raw binary data held in a string
  const byteString = atob(dataURL.split(',')[1]);

  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

  const arrayBuffer = new ArrayBuffer(byteString.length);
  
  const ia = new Uint8Array(arrayBuffer);
  
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], {type: mimeString});
  return blob;

};






  return (
    <div className="sm:-ml-[60px] xs:-ml-[60px] lg:ml-0 md:ml-0 ">
      <main
        id="fillLevel"
        className={calendarclick ? "-z-50" :"dark:text-white text-slate-90 sm:640px md:768px lg:1024px xl:1280px 2xl:1536px  mx-12 "}
      >
        <section className="topSection" class="flex xs:flex-col items-center lg:flex-row md:flex-row sm:flex-col justify-between text-base  lg:justify-between md:justify-between sm:justify-center sm:items-center xs:justify-center xs:items-center  ">

        <h2 class="text-lg font-semibold  pb-4 flex items-center justify-center gap-x-2">{language === "Dutch" ? "Totaal Genwicht" : "Total Weight"}
        <div className="flex flex-row items-center justify-center rounded-lg p-[6px] text-plaex-bar relative">
          <RiDeleteBin7Line className="w-8 h-8 text-black"/>
          <h3 className="text-[8px] mx-auto mt-1 absolute">{bin_identifier}</h3>
        </div>

        </h2>

          <div className="flex items-center justify-center space-x-3 -mt-3">

          {/* {tier === "Business" && (<div className='text-black w-auto transition-transform z-50 duration-300 ease-in-out transform hover:scale-[0.8] rounded-full flex justify-center items-center  cursor-pointer' data-tooltip-id="co2savings" data-tooltip-content="Export TotalWasteCount" onClick={handleExporttoExcel}>
            <FaFileExport className='w-6 h-6 text-plaex-bar'/>
          </div>)}
          <ReactTooltip id="co2savings"/> */}
                 {tier === 'Business' && ( <div className="flex items-center mr-7">

                  <div className="relative">
                  <div>
                  <button className="w-6 h-6 text-black underline hover:text-green-500 p-2 cursor-pointer" onClick={toggleDropdown}>
                  {language === "Dutch" ? "Exporteren" : "Export"}
                  </button>
                  {isDropdownOpen && (
                  <CSSTransition
                  in={isDropdownOpen}
                  timeout={200}
                  classNames="slide"
                  unmountOnExit
                  >
                  <div className="flex absolute top-[100%] mt-2 flex-row  bg-white border border-gray-300 p-1 rounded shadow">
                    <button onClick={() => handleExporttoExcel('xlsx')} className="text-black underline hover:text-green-500 p-2 text-sm">
                      XLSX
                    </button>
                    <button onClick={() => handleExporttoExcel('csv')} className="text-black underline hover:text-green-500 p-2 text-sm">
                      CSV
                    </button>
                    <button onClick={() => handleExporttoExcel('png')} className="text-black underline hover:text-green-500 p-2 text-sm">
                      PNG
                    </button>
                  </div>

                  </CSSTransition>
                  )}
                  </div>
                  </div>
                  </div>
                  )}

            <AiTwotoneCalendar className="text-3xl text-plaex_calendar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] cursor-pointer" onClick={handleCalendarClick}/>


  
                    <AiFillInfoCircle
                        className="h-5 w-5 text-plaex-bar hover:text-green-500 cursor-pointer" 
                        onClick={() => setModalIsOpen(!modalIsOpen)}
                      />

          </div>

        </section>

        <div className="flex flex-col gap-x-5 pl-0 ml-0  overflow-x-auto h-96">
          <section id="fillLevelChart" className={calendarclick ? "-z-50" : ""}>
            <Bar
              ref={chartRef} 
              data={wasteData}
              plugins={[ChartDataLabels]}
              options={options}
              // height={250}
              height
              // plugins={[barPattern]}
              // width={"1000%"}
            />
          </section>
          <section id="fillLevelCapacity" class="text-xs font-semibold">
            <div className="level">
              <div id="wasteFillLevel"></div>
              <p>{language === "Dutch" ? "Totaal Gewicht" : "Total Weight"}</p>
            </div>
          </section>
        </div>
      </main>
      {calendarclick && (
                <div className={calendarclick? "-mt-96 flex flex-col rounded-lg shadow-lg" : "-mt-96 flex flex-col rounded-lg shadow-lg"}>
                  <div className="flex items-start justify-start">
                    <button onClick={handleCloseCalendar} className="text-2xl font-black ml-6 mt-6 hover:text-plaex-bar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] "><AiOutlineClose/></button>
                  </div>
                    <CustomCalendar datevalue={datevalue} onDateValueChange={handleDateValueChange}/>
                </div>
        )}

{modalIsOpen && (
  <>
    <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 pb-10 z-50 w-full sm:max-w-md">
        <button onClick={handleModalClick} className="text-2xl font-black ml-6 mt-6 hover:text-plaex-bar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] "><AiOutlineClose/></button>
        <div>
          This interactive chart provides a visual representation of the total weight of the waste contained in the bin. Each data point on the chart corresponds to a specific weight of waste contained within the bin, allowing you to track and analyze weight trends over time. Hover over any point on the chart to see detailed information about the weight at that particular time. This tool aims to increase awareness and understanding of waste management, promoting more sustainable practices.
        </div>

      </div>
    </div>
  </>
)}

    </div>
  );
};

export default TotalWasteCount;
