import React, {useState, useEffect, useContext} from 'react'
import { LanguageContext } from 'context/LanguageContext';
import Navbar from 'common/Components/NavBar/NavBar';
import Hamburger from 'common/Components/Hamburger/Hamburger';
import axiosInstance from 'api/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";


const ChangePassword = () => {
    const {language} = useContext(LanguageContext)
    const [oldpassword, setOldPassword] = useState('')
    const [newpassword, setNewPassword] = useState('')
    const [openeye, setOpeneye] = useState(true);
    const [eyevisible,seteyevisible] = useState(false);
    const [openeye2, setOpeneye2] = useState(true);
    const [eyevisible2,seteyevisible2] = useState(false);

      const handleSetEyeStateOpen = () => {
            setOpeneye(false);
            seteyevisible(true);
        };

        const handleSetEyeStateClose = ()=>{
            setOpeneye(true);
            seteyevisible(false);
        };

        const handleSecondEyeOpen = ()=>{
            setOpeneye2(false);
            seteyevisible2(true);
        }
        const handleSecondEyeClose = ()=>{
            setOpeneye2(true);
            seteyevisible2(false);
        }

    const notifychange = () => {
        const message = language === "Dutch" ? "Wacthwoord Succesvol Gewijzigd !" : "Successfully Changed Password!";
        toast(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        progressClassName: 'my-progress-bar'
        })};

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const user = {
          password: oldpassword,
          new_password: newpassword,
        };
        try {
          await axiosInstance.post("user/change_password", user, {
            withCredentials: true,
          });
          notifychange()
          setOldPassword('')
          setNewPassword('')
        } catch (err) {
          console.log("err")
      };
    }
    
return (
    <div className=''>
        <Navbar/>
        <Hamburger />

        <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    
    <div className='h-full w-full flex justify-center items-center lg:ml-16 md:ml-24'>
        <form
              className=" mb-0 space-y-4 rounded-lg p-4 w-[50%] h-full shadow-lg sm:p-6 lg:p-8 flex my-64 flex-col"
              onSubmit={handleSubmit}
            >
              <div className='relative'>
              <input placeholder={language ==="Dutch" ? "Oud Wachtwoord Invoeren" : "Enter Old Password"} type={eyevisible ? "text" : "password"} value={oldpassword} className='w-full rounded-lg border-2 p-4 pr-12 text-sm shadow-sm focus:border-green-500 focus:outline-none' onChange={(e) => setOldPassword(e.target.value)}/>
              <span className="absolute inset-y-0  cursor-pointer right-0 grid place-content-center px-4">
                    {
                      openeye === true ? <AiOutlineEye className="h-5 w-5 text-gray-400 hover:text-plaex_eye_hide_unhide focus:text-plaex_eye_hide_unhide active:text-plaex_eye_hide_unhide" onClick={()=>handleSetEyeStateOpen()}/>
                      :
                      <AiOutlineEyeInvisible className="h-5 w-5 text-gray-400 hover:text-plaex_eye_hide_unhide focus:text-plaex_eye_hide_unhide active:text-plaex_eye_hide_unhide" onClick={()=>handleSetEyeStateClose()}/>
                    }
                  </span>
                </div>

                <div className='relative'>
                <input placeholder={language === "Dutch" ? "Nieuw Wachtwoord Invoeren" : "Enter New Password"} value={newpassword} type={eyevisible2 ? "text" : "password"} className='w-full rounded-lg border-2 p-4 pr-12 text-sm shadow-sm focus:border-green-500 focus:outline-none' onChange={(e) => setNewPassword(e.target.value)}/>
                <span className="absolute inset-y-0  cursor-pointer right-0 grid place-content-center px-4">
                    {
                      openeye2 === true ? <AiOutlineEye className="h-5 w-5 text-gray-400 hover:text-plaex_eye_hide_unhide focus:text-plaex_eye_hide_unhide active:text-plaex_eye_hide_unhide" onClick={()=>handleSecondEyeOpen()}/>
                      :
                      <AiOutlineEyeInvisible className="h-5 w-5 text-gray-400 hover:text-plaex_eye_hide_unhide focus:text-plaex_eye_hide_unhide active:text-plaex_eye_hide_unhide" onClick={()=>handleSecondEyeClose()}/>
                    }
                  </span>
                </div>

              <button
                type="submit"
                className="block w-full rounded-lg bg-green-600 px-5 py-3 text-sm font-medium text-white hover:bg-green-400 hover:text-gray-800"
              >
                {language === "Dutch" ? "Verander Wachtwoord" : "Change Password"}
              </button>
        </form>
    </div>
    </div>
)
}

export default ChangePassword;
