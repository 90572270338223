import logo from "Assets/images/logo_white.png";
import back_video from "Assets/videos/Login_background.mp4";
import axiosInstance from "api/axios";
import { useRef, useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "context/LanguageContext";

import "./Login.css";
function SignUpPage() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [phonenumber, setPhoneNumber] = useState("")
  const [kvknumber, setKvKNumber] = useState("")
  const [garfill, setGarfill] = useState(0);
  const [garby, setGarby] = useState(0);
  const [garcia, setGarcia] = useState(0);

  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");
  
  const {language} = useContext(LanguageContext)

  function decrementGarfill(e) {
    if (garfill > 0) {
      setGarfill(garfill - 1);
    } else {
      setGarfill(0);
    }
  }

  function incrementGarfill(e) {
    setGarfill(garfill + 1);
  }

  function decrementGarby(e) {
    if (garby > 0) {
      setGarby(garby - 1);
    } else {
      setGarby(0);
    }
  }

  function incrementGarby(e) {
    setGarby(garby + 1);
  }
  function decrementGarcia(e) {
    if (garcia > 0) {
      setGarcia(garcia - 1);
    } else {
      setGarcia(0);
    }
  }

  function incrementGarcia(e) {
    setGarcia(garcia + 1);
  }
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    try {
      axiosInstance.get("user/send-email", {
        params: {
          name: name,
          lastname: lastName,
          organization: organization,
          kvknumber: kvknumber,
          phonenumber: phonenumber,
          email: email,
          garby: garby,
          garcia: garcia,
          garfill: garfill,
        },
      });
      navigate("/thanks");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else {
        console.log(err.response);
        setErrMsg("Sign Up Failed");
      }
    }
  };

  return (
    <div className="relative h-screen">
      <div className="absolute inset-0 z-0">
        <video className="object-cover object-center w-full lg:h-[130%] md:h-[130%] sm:h-[170%] xs:h-[170%]" autoPlay muted loop>
          <source src={back_video} type="video/mp4" />
        </video>
      </div>
      <div className="relative z-10 flex items-center justify-center ">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 flex items-center justify-center">
          <div className="mx-auto max-w-lg">
            <div className="mx-auto w-1/6 pb-5">
              <img src={logo} alt="logo" />
            </div>
            <h1 className="text-center text-2xl font-bold text-green-600 sm:text-3xl">
              Get started today with Us
            </h1>

            <p className="mx-auto mt-4 max-w-md text-center text-white">
              {language === "Dutch" ? "Doe Met Ons Mee in Onze Missie Om De Wereld Een Schonere en Gezondere Plek Te Maken" : "Join us in our mission to make the world a cleaner and healthier place!"}
            </p>
            {/* Error message*/}
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscrean"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <form
              className="mt-6 mb-0 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
              onSubmit={handleSubmit}
              na
            >
              <p className="text-center font-medium text-green-500 text-2xl">
                {language === "Dutch" ? "Meld Je Aan Voor PLAEX": "Sign up to PLAEX"}
              </p>

              <div>
                <label htmlFor="text" className="sr-only">
                  Name
                </label>

                <div className="relative">
                  <input
                    type="text"
                    id="name"
                    className="w-full rounded-lg border-2 p-4 pr-12 text-sm shadow-sm focus:border-green-500 focus:outline-none"
                    placeholder={language === "Dutch" ? "Naam" : "Name"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="text" className="sr-only">
                  Last Name
                </label>

                <div className="relative">
                  <input
                    type="text"
                    id="lastname"
                    className="w-full rounded-lg border-2 p-4 pr-12 text-sm shadow-sm focus:border-green-500 focus:outline-none"
                    placeholder={language === "Dutch" ?  "Achternaam": "Last Name"} 
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="text" className="sr-only">
                  Organization
                </label>

                <div className="relative">
                  <input
                    type="text"
                    id="organization"
                    className="w-full rounded-lg border-2 p-4 pr-12 text-sm shadow-sm focus:border-green-500 focus:outline-none"
                    placeholder={language === "Dutch" ?  "Organisatie": "Organization"} 
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                    required
                  />
                </div>
              </div>
                            <div>
                <label htmlFor="text" className="sr-only">
                  Phonenumber
                </label>

                <div className="relative">
                  <input
                    type="tel"
                    id="number"
                    className="w-full rounded-lg border-2 p-4 pr-12 text-sm shadow-sm focus:border-green-500 focus:outline-none"
                    placeholder={language === "Dutch" ?  "Nummer": "Number"} 
                    value={phonenumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="text" className="sr-only">
                  KVK Number
                </label>

                <div className="relative">
                  <input
                    type="text"
                    id="kvknumber"
                    className="w-full rounded-lg border-2 p-4 pr-12 text-sm shadow-sm focus:border-green-500 focus:outline-none"
                    placeholder={language === "Dutch" ?  "KvK Nummer": "Kvk Number"} 
                    value={kvknumber}
                    onChange={(e) => setKvKNumber(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>

                <div className="relative">
                  <input
                    type="email"
                    id="email"
                    className="w-full rounded-lg border-2 p-4 pr-12 text-sm shadow-sm focus:border-green-500 focus:outline-none"
                    placeholder={language === "Dutch" ? "Voer Email" : "Enter email"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />

                  <span className="absolute inset-y-0 right-0 grid place-content-center px-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              
              <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1">
              <div>
                <div class="custom-number-input h-10 w-32">
                  <label
                    for="custom-input-number"
                    class="w-full text-white text-sm font-semibold"
                  >
                    Garfill
                  </label>
                  <div class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                    <button
                      type="button"
                      onClick={decrementGarfill}
                      class=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
                    >
                      <span class="m-auto text-2xl font-thin">-</span>
                    </button>
                    <input
                      type="number"
                      class=" focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                      name="custom-input-number"
                      value={garfill}
                    ></input>
                    <button
                      type="button"
                      onClick={incrementGarfill}
                      class="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
                    >
                      <span class="m-auto text-2xl font-thin">+</span>
                    </button>
                  </div>
                </div>
                <br />
              </div>
              <div>
                <div class="custom-number-input h-10 w-32">
                  <label
                    for="custom-input-number"
                    class="w-full text-white text-sm font-semibold"
                  >
                    Garby
                  </label>
                  <div class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                    <button
                      type="button"
                      onClick={decrementGarby}
                      class=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
                    >
                      <span class="m-auto text-2xl font-thin">-</span>
                    </button>
                    <input
                      type="number"
                      class=" focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                      name="custom-input-number"
                      value={garby}
                    ></input>
                    <button
                      type="button"
                      onClick={incrementGarby}
                      class="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
                    >
                      <span class="m-auto text-2xl font-thin">+</span>
                    </button>
                  </div>
                </div>
                <br />
              </div>
              <div>
                <div class="custom-number-input h-10 w-32">
                  <label
                    for="custom-input-number"
                    class="w-full text-white text-sm font-semibold "
                  >
                    Garcia
                  </label>
                  <div class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                    <button
                      type="button"
                      onClick={decrementGarcia}
                      class=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
                    >
                      <span class="m-auto text-2xl font-thin">-</span>
                    </button>
                    <input
                      type="number"
                      class=" focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                      name="custom-input-number"
                      value={garcia}
                    ></input>
                    <button
                      type="button"
                      onClick={incrementGarcia}
                      class="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
                    >
                      <span class="m-auto text-2xl font-thin">+</span>
                    </button>
                  </div>
                </div>
                <br />
              </div>
              </div>

              <button
                type="submit"
                className="block w-full rounded-lg bg-green-600 px-5 py-3 text-sm font-medium text-white"
              >
                {language === "Dutch" ? "Inschrijven" : "Sign Up"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpPage;
