import residual from "Assets/svg/residual.svg";
import Button from "common/Components/Button/Button";
import Navbar from "common/Components/NavBar/NavBar";
import Sidebar from "common/Components/SideBar/SideBar";
import React, { useState, useEffect } from "react";
import axiosInstance from "api/axios";
import { useLocation,useNavigate } from "react-router-dom";
import "../Waste-Dashboard/WasteDashboard.css";
import Hamburger from "common/Components/Hamburger/Hamburger";
import "../Waste-Dashboard/WasteDashboard.css"
import {AiOutlineArrowDown} from 'react-icons/ai'
import {AiOutlineArrowUp} from 'react-icons/ai'

import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
import GHGChart from "./GHGChart";


const GHGDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const wasteitem = pathname.split("/")[3];
  const id = pathname.split("/")[2];
  const [selectedValue, setSelectedValue] = useState("24hrs");
  const [dayvalue, setDayValue] = useState("");
  const [selectedButton, setSelectedButton] = useState('day');
  const [binIds, setBinIds] = useState([]);
  const [selectedstream, setSelectedStream] = useState("organic");
  const [currentWasteItem, setCurrentWasteItem] = useState(wasteitem);
  const [currentbinid, setCurrentBinId] = useState(id);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [totalwasteproduced, setTotalWasteProduced] = useState()
  const [colorwaste, setColorWaste] = useState()
  const [percentagechange, setPercentageChange] = useState()
  const { language } = useContext(LanguageContext);
  const [TotalWasteWeight, setTotalWasteWeight]  = useState('')

  const ghgData = [
    { label: 'Jan', value: 10 },
    { label: 'Feb', value: 15 },
    { label: 'Mar', value: 8 },
    { label: 'Apr', value: 20 },
    // Add more data points as needed
  ];


  const handleClick = (btnvalue) => {
    setDayValue(btnvalue);
    setSelectedButton(btnvalue);
  };

  useEffect(() => {
    axiosInstance
      .get('/get_bins')
      .then((response) => {
        setBinIds(response.data.map((item) => item.unique_identifier));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axiosInstance
      .get("/totalwasteproducedwithoutstream", {
        params: {
          id: id,
          day: dayvalue,
        },
      })
      .then((response) => {
        setPercentageChange(parseFloat(response.data.percentage_change).toFixed(2))
        setColorWaste(response.data.color)
        setTotalWasteProduced(response.data.total_waste);
        setTotalWasteWeight(response.data.waste_weight)
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dayvalue, wasteitem, id]);
  


    const handleBinDropDownChange = (event) =>{
      const newSelectedBin = event.target.value;
      setCurrentBinId(newSelectedBin);
      // navigate(`/SubclassDisplay/${newSelectedBin}/`);
    }

    const translations = {
      day: 'dag',
      week: 'week',
      month: 'maand',
      '3months': '3 maanden',
      '6months': '6 maanden',
      year: 'jaar'
    };
    const translatedSelectedButton = language === "Dutch" ? translations[selectedButton] : selectedButton;
    const message = `Totaal Afval in Vergelijking Met Vorige ${translatedSelectedButton}`;


    return (
        <div
          style={{ minHeight: "100vh" }}
          class="bg-white dark:bg-plaex-dark dark:text-white"
          // id="wasteDashboard"
        >
          {/* Nav and Side Bar Import */}
          <Navbar />
          {/* <Sidebar onBinClick={handleClick} /> */}
          <Hamburger onBinClick={handleClick} />
    
          {/* Buttons for filtering waste by duration */}
          <div className="h-full ml-16 mt-24 mb-10 md:ml-64 p-4 lg:ml-64 xs:ml-0 sm:ml-0">
          <div className="Waste-Filter-Container">
            <div className="Waste-Filter-Container-title sm:flex-col xs:flex-col lg:flex-row md:flex-row text-xl sm:-mt-4 xs:-mt-4 lg:mt-0 md:mt-0 w-full lg:justify-start xs:justify-center sm:justify-center md:justify-start items-center capitalize flex flex-row gap-x-2 sm:gap-y-3 xs:gap-y-3">
              <div className="flex lg:flex-row md:flex-col sm:flex-col xs:flex-col  items-center w-full justify-between space-x-3">
          
                <div
                  className="capitalize bg-plaex-light-green mb-4 flex flex-row p-1 text-center items-center justify-center rounded-lg"
                >
                  <img src={residual} className="h-6 w-6"/>
                  {/* <span className="text-[18px]">{currentbinid}</span> */}
                  <select className="select bg-plaex-light-green text-sm" size="1" onChange={handleBinDropDownChange}>
                    {binIds.map((binId) => (
                      <option key={binId} value={binId}>
                        {binId}
                      </option>
                    ))}
                      {binIds.length > 0 && <option value="all">All</option>}
                  </select>
                </div>
    
                <div className="Filter-Buttons">
                    <Button
                        text={language === "Dutch" ? "1 Dag" : "1 Day"}
                        onClick={(event) => handleClick("day")}
                        value="day"
                        className={selectedButton === 'day' ? 'selected' : ''}
                    />
                    <Button
                        text="1 Week"
                        onClick={(event) => handleClick("week")}
                        value="week"
                        className={selectedButton === 'week' ? 'selected' : ''}
                    />
                    
                    <Button
                        text={language === "Dutch" ? "1 Maand" : "1 Month"}
                        onClick={(event) => handleClick("month")}
                        value="month"
                        className={selectedButton === 'month' ? 'selected' : ''}
                    />
                    <Button
                        text= {language === "Dutch" ? "3 Maanden" : "3 Months"}
                        onClick={(event) => handleClick("3months")}
                        value="3months"
                        className={selectedButton === '3months' ? 'selected' : ''}
                    />
                    <Button
                        text={language === "Dutch" ? "6 Maanden" : "6 Months"}
                        onClick={(event) => handleClick("6months")}
                        value="6months"
                        className={selectedButton === '6months' ? 'selected' : ''}
                    />
                    <Button
                        text={language === "Dutch" ? "1 Jaar" : "1 Year"}
                        onClick={(event) => handleClick("year")}
                        value="year"
                        className={selectedButton === 'year' ? 'selected' : ''}
                    />
                </div>
    
    
              </div>
            </div>
    
            <div className="mt-4 flex flex-col items-center justify-center">
            <span className="text-green-700  text-[13px]">{language === "Dutch" ? `${message}` : `Total Waste compared to previous ${selectedButton}`}</span>
                <div className="flex items-center justify-center gap-4">
                <span className="font-semibold flex items-center justify-center text-base">{totalwasteproduced} &nbsp; <span className="font-light">Objects</span> &nbsp;<span className="font-light">|</span>&nbsp;{TotalWasteWeight} &nbsp; <span className="font-light">KG</span></span>{" "}
                  <span className={`text-${colorwaste}-500 flex items-center justify-center text-[13px]`}>{percentagechange}% {colorwaste == "red"? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>}</span>
                </div>
            </div>
    
          </div>
            
        <div className="ghg-dashboard">
            <h1>Greenhouse Gas (GHG) Emissions Dashboard</h1>
            <GHGChart data={ghgData} />
        </div>
        
          </div>
        </div>
      );
};

export default GHGDashboard;


