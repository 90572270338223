import axiosInstance from "api/axios";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import Button from "common/Components/Button/Button";
import { useEffect, useState, useContext, useRef } from "react";
import { Line } from "react-chartjs-2";
import "./BinChart.css";
import { LanguageContext } from "context/LanguageContext";
import {RiDeleteBin7Line} from "react-icons/ri"
import * as XLSX from 'xlsx';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {FaFileExport} from 'react-icons/fa'
import { BiMenu } from "react-icons/bi";
import CustomCalendar from "common/Components/Calendar/Calendar";
import {AiTwotoneCalendar, AiOutlineClose} from "react-icons/ai"
import {AiOutlineArrowDown} from 'react-icons/ai'
import {AiOutlineArrowUp} from 'react-icons/ai'
import { CSSTransition } from 'react-transition-group';
import {AiFillInfoCircle} from 'react-icons/ai'
import html2canvas from 'html2canvas';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function BinChart(props) {
  const [chartData, setChartData] = useState({});
  const [lineChartDate, setlineChartDate] = useState("day");
  const [selectedBin, setSelectedBin] = useState("0");
  const [totalWaste, setTotalWaste] = useState();
  const [selectedButton, setSelectedButton] = useState('day');
  const [tier, setTier] = useState('')
  const { language } = useContext(LanguageContext);
  const [selectedFormat, setSelectedFormat] = useState('xlsx');
  const [calendarclick, setCalendarClick] = useState(false);
  const [totalwasteproduced, setTotalWasteProduced] = useState()
  const [colorwaste, setColorWaste] = useState()
  const [percentagechange, setPercentageChange] = useState()
  const [TotalWasteWeight, setTotalWasteWeight]  = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [datevalue, setDateValue] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  const resetDates = () => {
    setDateValue([
      {
        startDate: null,
        endDate: null,
        key: 'selection'
      }
    ]);
  };


  const handleCloseCalendar = () => {
    setCalendarClick(false);
  }
  const handleDateValueChange = (newDateValue) => {
    setDateValue(newDateValue);
    setCalendarClick(false)
  }

  const handleCalendarClick = () =>{
    setCalendarClick(!calendarclick);
  }

  const handleModalClick = () =>{
    setModalIsOpen(false)
  }

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const chartRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const week = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [month, setMonth] = ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"];
  const [month3, setMonth3] = useState(["Month 1", "Month 2", "Month 3"]);
  const day = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];
  const [lineChartLabel, setlineChartLabel] = useState(day);
  const [theme, setTheme] = useState();

  const translations = {
    day: 'dag',
    week: 'week',
    month: 'maand',
    '3months': '3 maanden',
    '6months': '6 maanden',
    year: 'jaar'
  };

  const translatedSelectedButton = language === "Dutch" ? translations[selectedButton] : selectedButton;
  const message = `Totaal Afval in Vergelijking Met Vorige ${translatedSelectedButton}`;

  const translateToDutch = (text) => {
    const translations = {
      "Monday": "Maandag",
      "Tuesday": "Dinsdag",
      "Wednesday": "Woensdag",
      "Thursday": "Donderdag",
      "Friday": "Vrijdag",
      "Saturday": "Zaterdag",
      "Sunday": "Zondag",
      "January": "Januari",
      "February": "Februari",
      "March": "Maart",
      "April": "April",
      "May": "Mei",
      "June": "Juni",
      "July": "Juli",
      "August": "Augustus",
      "September": "September",
      "October": "Oktober",
      "November": "November",
      "December": "December",
      "Organic": "Biologisch",
      "PMD": "PMD",
      "Paper": "papier",
      "Residual": "Resterend",
    };
    return translations[text] || text;
  }

  useEffect(() => {
    axiosInstance
        .get("/usertier")
        .then((response) => {
            setTier(response.data.tier)
        })
        .catch((error) => {
        console.error(error);
        });
    }, []);

    useEffect(() => {
      if (selectedBin === undefined) {
        axiosInstance
          .get(`/totalwastebindash/0/${lineChartDate}`)
          .then((response) => {
            setPercentageChange(parseFloat(response.data.percentage_change).toFixed(2))
            setColorWaste(response.data.color)
            setTotalWasteProduced(response.data.total_waste_current)
            setTotalWasteWeight(response.data.waste_weight)
          });
      } else {
        setSelectedBin(props.bin);
        axiosInstance
          .get(`/totalwastebindash/${selectedBin.id}/${lineChartDate}`)
          .then((response) => {
            setPercentageChange(parseFloat(response.data.percentage_change).toFixed(2))
            setColorWaste(response.data.color)
            setTotalWasteProduced(response.data.total_waste_current)
                    setTotalWasteWeight(response.data.waste_weight)
          });
      }
    }, [lineChartDate, props, selectedBin]);
    

  useEffect(() => {
    // if (theme === undefined) {
    //   setTheme(localStorage.theme === "dark" ? "white" : "black");
    // } else {
    //   setTheme(props.darkM);
    // }
    if (selectedBin === undefined) {
      axiosInstance
        .get(`/get_waste_data/0/${lineChartDate}`)
        .then((response) => {
          setTotalWaste(response.data.total_waste_collected);
        });
    } else {
      setSelectedBin(props.bin);
      axiosInstance
        .get(`/get_waste_data/${selectedBin.id}/${lineChartDate}`)
        .then((response) => {
          setTotalWaste(response.data.total_waste_collected);
        });
    }
  }, [props, lineChartDate, selectedBin]);
  const handleClick = (e) => {};

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        position: "top",
        align: "end",
        labels: {
          usePointStyle: true,
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "right",
        beginAtZero: true,
        grid: {
          display: true,
          // drawOnChartArea: false,
        },
        ticks: {
          color: theme,
        },
      },
      x: {
        display: true,
        beginAtZero: true,
        grid: {
          display: false,
          // drawOnChartArea: false,
        },
        ticks: {
          color: theme,
        },
      },
    },
  };


  useEffect(() => {
    const startDate = new Date(datevalue[0].startDate)
    const endDate = new Date(datevalue[0].endDate)
    const params = {
      'startDate': startDate.toString(),
      'endDate': endDate.toString(),
    };

    axiosInstance
    .get(`/get_waste_weight/${selectedBin.id}/${lineChartDate}`, {
      withCredentials: true,
      params: params,
    })
      .then((response) => {
        if (
  lineChartDate === "month" ||
  lineChartDate === "3month" ||
  lineChartDate === "6month" ||
  lineChartDate === "year"
) {
  setChartData({
    labels: response.data.label.map(label => language === 'Dutch' ? translateToDutch(label) : label),
    datasets: response.data.results.map((dataset, index) => {
      let borderColor, backgroundColor;
      if (index === 0) {
        borderColor = "#004800";
        backgroundColor = "#004800";
      } else if (index === 1) {
        borderColor = "#EAD200";
        backgroundColor = "#EAD200";
      } else if (index === 2) {
        borderColor = "#4D89FF";
        backgroundColor = "#4D89FF";
      } else if (index === 3) {
        borderColor = "#B07F00";
        backgroundColor = "#B07F00";
      }
      return {
        ...dataset,
        label: language === 'Dutch' ? translateToDutch(dataset.label) : dataset.label,
        borderColor,
        backgroundColor,
        pointRadius: 3,
      };
    }),
  });
} else {
  setChartData({
    labels: response.data.label.map(label => language === 'Dutch' ? translateToDutch(label) : label),
    datasets: response.data.results.map((dataset, index) => {
      let borderColor, backgroundColor;
      if (index === 0) {
        borderColor = "#004800";
        backgroundColor = "#004800";
      } else if (index === 1) {
        borderColor = "#EAD200";
        backgroundColor = "#EAD200";
      } else if (index === 2) {
        borderColor = "#4D89FF";
        backgroundColor = "#4D89FF";
      } else if (index === 3) {
        borderColor = "#B07F00";
        backgroundColor = "#B07F00";
      }
      return {
        ...dataset,
        label: language === 'Dutch' ? translateToDutch(dataset.label) : dataset.label,
        borderColor,
        backgroundColor,
        pointRadius: 3,
      };
    }),
  });
}
      })
  }, [lineChartDate, selectedBin, datevalue]);

  const exportToExcel = (chartData) => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    let streamvalue = language === "Dutch" ? "Afval Vergelijking" : "Waste Comparison"
    // Prepare the data
    const data = chartData.labels.map((label, index) => {
      let Date = language === "Dutch" ? "Datum": "Date";
      return chartData.datasets.reduce((obj, dataset) => {
        obj[dataset.label] = dataset.data[index];
        return obj;
      }, {[Date]: label});
    });
  
    // Convert the data to worksheet
    const ws = XLSX.utils.json_to_sheet(data);
  
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
  
    // Write the workbook to a file
    XLSX.writeFile(wb, `${streamvalue}_${formattedDate}.xlsx`);
  };

  const exportToCSV = (chartData) => {
    let streamvalue = language === "Dutch" ? "Afval Vergelijking" : "Waste Comparison";
  
    // Prepare CSV data
    const csvData = [];
    
    // Create the header row
    const headerRow = ["Date", ...chartData.datasets.map(dataset => dataset.label)]; // Modify headers as needed
    csvData.push(headerRow);
  
    // Create rows with data
    chartData.labels.forEach((label, index) => {
      const rowData = [label, ...chartData.datasets.map(dataset => dataset.data[index])]; // Modify data fields as needed
      csvData.push(rowData);
    });
  
    // Convert data to CSV string
    const csvContent = csvData.map((row) => row.join(",")).join("\n");
  
    // Create a Blob and trigger a download
    const blob = new Blob([csvContent], { type: "text/csv" });
    if (window.navigator.msSaveBlob) {
      const date = new Date();
      const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
      // For IE
      window.navigator.msSaveBlob(blob, `${streamvalue}_${formattedDate}.csv`);
    } else {
      // For modern browsers
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${streamvalue}.csv`;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  


  const handleExporttoExcel = (option) => {
    if (option === 'xlsx') {
      exportToExcel(chartData);
    } else if (option === 'csv') {
      exportToCSV(chartData);
    } else if (option === 'png') {
      exportChartAsPNG();
    }
  }


  const exportChartAsPNG = () => {

    const chartSection = document.getElementById('BinChartContainer');
  
    html2canvas(chartSection).then(canvas => {
  
      const image = canvas.toDataURL('image/png');
  
      // Generate filename
      let streamvalue = language === "Dutch" ? "Afval Vergelijking" : "Waste Comparison"
      const date = new Date();
      const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
      const filename = `${streamvalue}_${date}.png`;
  
      // Pass filename to save function
      saveDataURLToFile(image, filename);
  
    });
  
  };
  const saveDataURLToFile = (dataURL, filename) => {
  
    // convert data URL to blob
    const blob = dataURLToBlob(dataURL);  
  
    // create object URL from blob 
    const url = URL.createObjectURL(blob);
  
    // create anchor tag
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
  
    // click to download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
  };
  
  const dataURLToBlob = (dataURL) => {
    // Convert base64 to raw binary data held in a string
    const byteString = atob(dataURL.split(',')[1]);
  
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  
    const arrayBuffer = new ArrayBuffer(byteString.length);
    
    const ia = new Uint8Array(arrayBuffer);
    
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
  
    const blob = new Blob([arrayBuffer], {type: mimeString});
    return blob;
  
  };
  


  return (
    <div id="bin-chart" className="text-slate-900 lg:ml-0 md:ml-0 sm:-ml-[40px] xs:-ml-[40px]  mb-16 relative">
      <div className="bin-chart-options flex lg:flex-row md:flex-col sm:flex-col xs:flex-col">
      <div className="uppercase text-lg dark:text-white flex  mb-4 lg:mt-0 md:mt-0 xs:-mt-4 sm:-mt-4 items-center lg:justify-start md:justify-center xs:justify-center sm:justify-center">
          <RiDeleteBin7Line className="w-8 h-8 text-black"/>
          <h3 className="text-base font-semibold">{selectedBin.unique_identifier}</h3>
        </div>
        <div className="bin-options ml-auto flex-shrink-0">
          <Button
            text={language === "Dutch" ? "1 Dag" : "1 Day"}
            onClick={() => {
              resetDates()
              setlineChartDate("day");
              setlineChartLabel(day);
              setSelectedButton("day")
            }}
            className={selectedButton === 'day' ? 'selected' : ''}
          />
          <Button
            text="1 Week"
            onClick={() => {
              resetDates()
              setlineChartDate("week");
              setlineChartLabel(week);
              setSelectedButton("week")
            }}
            className={selectedButton === 'week' ? 'selected' : ''}
          />
          <Button
            text={language === "Dutch" ? "1 Maand" : "1 Month"}
            onClick={() => {
              resetDates()
              setlineChartDate("month");
              //setlineChartLabel(month);
              setSelectedButton("month")
            }}
            className={selectedButton === 'month' ? 'selected' : ''}
          />
          <Button
            text= {language === "Dutch" ? "3 Maanden" : "3 Months"}
            onClick={() => {
              resetDates()
              setlineChartDate("3month");
              //setlineChartLabel(month3);
              setSelectedButton("3months")
            }}
            className={selectedButton === '3months' ? 'selected' : ''}
          />
          <Button
            text={language === "Dutch" ? "6 Maanden" : "6 Months"}
            onClick={() => {
              resetDates()
              setlineChartDate("6month");
              //setlineChartLabel(month3);
              setSelectedButton("6months")
            }}
            className={selectedButton === '6months' ? 'selected' : ''}
          />
          <Button
            text={language === "Dutch" ? "1 Jaar" : "1 Year"}
            onClick={() => {
              resetDates()
              setlineChartDate("year");
              setSelectedButton("year")
              //setlineChartLabel(month3);
            }}
            className={selectedButton === 'year' ? 'selected' : ''}
          />
        </div>
      </div>
      {/* Total Waste count */}
      <div className="waste-count ">
        {/* <h1 className="Total-Waste-h1 dark:text-green-300 text-base">{language ==="Dutch" ? "Totaal Afval": "Total Waste"}:</h1> */}
        {/* <h1 className="Number-of-Wastes "> */}
          {/* {totalWaste}{" "} */}
          {/* 
          <span className="Waste-Increase dark:text-red-500">-123% </span>
          */}
        {/* </h1> */}
        <div className="mt-4 flex flex-col items-center justify-center">
            <span className="text-green-700  text-[13px]">{language === "Dutch" ? `${message}` : `Total Waste compared to previous ${selectedButton}`}</span>
            <div className="flex items-center justify-center gap-4">
              <span className="font-semibold flex items-center justify-center text-base">{totalwasteproduced} &nbsp; <span className="font-light">Objects</span> &nbsp;<span className="font-light">|</span>&nbsp;{TotalWasteWeight} &nbsp; <span className="font-light">KG</span></span>{" "}
              <span className={`text-${colorwaste}-500 flex items-center justify-center text-[13px]`}>{percentagechange}% {colorwaste == "red"? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>}</span>
            </div>
        </div>
      </div>
      {/* Waste Level Chart  */}
      {tier === 'Business' && ( <div className="flex text-center items-center mr-1">
      <AiFillInfoCircle
                        className="h-5 w-5 text-plaex-bar hover:text-green-500 cursor-pointer mr-1" 
                        onClick={() => setModalIsOpen(!modalIsOpen)}
                      />
                        <AiTwotoneCalendar className="text-2xl text-plaex_calendar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] cursor-pointer" onClick={handleCalendarClick}/>
                        <div className="relative -mt-1">
                  <div>
                    <button className="w-6 h-6 text-black underline hover:text-green-500 p-2 cursor-pointer" onClick={toggleDropdown}>
                      {language === "Dutch" ? "Exporteren" : "Export"}
                    </button>
                    {isDropdownOpen && (
                      <CSSTransition
                        in={isDropdownOpen}
                        timeout={200}
                        classNames="slide"
                        unmountOnExit
                      >
                        <div className="absolute flex flex-row left-16 -top-2 bg-white border border-gray-300 p-1 rounded shadow">
                          <button onClick={() => handleExporttoExcel('xlsx')} className="text-black underline hover:text-green-500 p-2 text-sm">
                            XLSX
                          </button>
                          <button onClick={() => handleExporttoExcel('csv')} className="text-black underline hover:text-green-500 p-2 text-sm">
                            CSV
                          </button>
                          <button onClick={() => handleExporttoExcel('png')} className="text-black underline hover:text-green-500 p-2 text-sm">
                            PNG
                          </button>
                        </div>

                      </CSSTransition>
                    )}
                  </div>
                </div>
                      </div>
              )}


      <ReactTooltip id="co2savings"/>
      <div className="waste-level-chart">
        <div className="waste-level-chart-content" id="BinChartContainer">
          {chartData.labels && chartData.datasets && (
            <Line data={chartData} options={options} id="binLevelChart" ref={chartRef}  />
          )}
        </div>
      </div>

      {modalIsOpen && (
          <>
            <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg shadow-lg p-6 pb-10 z-50 w-full sm:max-w-md">
                <button onClick={handleModalClick} className="text-2xl font-black ml-6 mt-6 hover:text-plaex-bar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] "><AiOutlineClose/></button>
                <div>
                This interactive chart provides a comprehensive view of the different wastes generated over time. You can filter the chart by  date range to analyze trends and patterns in your Waste Comparison Page. Hover over any data point to see the exact figures for a particular time. Use the filters above the chart to focus on date ranges for the waste streams.
                Switch between the different bins using the dropdown on the side bar. This chart allows you to compare and contrast trends across the different waste categories without the need to switch to differnt waste streams. You can export the filtered chart data as a CSV or XSLX file for further analysis and use in other tools. 
                </div>

              </div>
            </div>
          </>
        )}

      {calendarclick && (
                <div className={calendarclick? "z-50 flex flex-col rounded-lg shadow-lg absolute bg-white top-32 w-full " : "z-50 flex flex-col rounded-lg shadow-lg absolute top-32 w-full bg-white "}>
                  <div className="flex items-start justify-start">
                    <button onClick={handleCloseCalendar} className="text-2xl font-black ml-6 mt-6 hover:text-plaex-bar transition-transform duration-300 ease-in-out transform hover:scale-[0.8] "><AiOutlineClose/></button>
                  </div>
                    <CustomCalendar datevalue={datevalue} onDateValueChange={handleDateValueChange}/>
                </div>

        )}
    </div>
  );
}
