import axiosInstance from "api/axios";
import { useEffect, useState, useContext } from "react";
import {
  AiFillDatabase,
  AiFillLeftSquare,
  AiFillRightSquare,
} from "react-icons/ai";
import "./BinWasteTable.css";
import { LanguageContext } from "context/LanguageContext";
import {FiFilter} from 'react-icons/fi'
import {BsCalendar2DateFill} from 'react-icons/bs'
import {GiWeightScale} from 'react-icons/gi'
import { MdArrowBack, MdArrowDownward, MdArrowUpward } from "react-icons/md";
import {AiOutlineClear} from 'react-icons/ai'


export default function BinWasteTable(props) {
  const [tabledata, setTableData] = useState([]);
  const [tableDate, setTableDate] = useState("");
  const [selectedBin, setSelectedBin] = useState("0");

  const [pagination, setPagination] = useState(null);
  const [pagenumber, setPageNumber] = useState(null);
  const { language } = useContext(LanguageContext);
  const [sortDirectionWeight, setSortDirectionWeight] = useState("default");
  const [sortDirectionTime, setSortDirectionTime] = useState("default");
  const [sortBy, setSortBy] = useState('weight');

  const [filterclick, setFilterClick] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null);

  const [counter, setCounter] = useState(1);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

   const handleFilterClick = ()=>{
    setFilterClick(!filterclick);
  }

  const handleFilterChange = (value) => {
    switch (value) {
      case 'weightAsc':
        setSortBy('weight');
        setSortDirectionWeight(true);
        break;
      case 'weightDesc':
        setSortBy('weight');
        setSortDirectionWeight(false);
        break;
      case 'timeAsc':
        setSortBy('time');
        setSortDirectionTime(true);
        break;
      case 'timeDesc':
        setSortBy('time');
        setSortDirectionTime(false);
        break;
      case 'clear':
        setSortBy('');
        setSortDirectionTime("default");
        setSortDirectionWeight("default");
        break;
    }
  }

  const handlePrevClick = () => {
    if (pagination && pagination.previous) {
      if (counter === 1) {
        setCounter(counter);
      } else {
        setCounter(counter - 1);
      }
      //fetchWasteData(pagination.previous);
      //Replace http with https to avoid mixed content error
      fetchWasteData(pagination.previous.replace(/^http:\/\//i, "https://"));
    }
  };

  const translateWasteToDutch = (text) => {
    const translations = {
      "organic": "Biologisch",
      "PMD": "PMD",
      "paper": "papier",
      "rest": "Resterend"
    };
    return translations[text] || text;
  }

  const handleNextClick = () => {
    if (pagination && pagination.next) {
      if (counter >= pagenumber) {
        setCounter(counter);
      } else {
        setCounter(counter + 1);
      }
      //fetchWasteData(pagination.next);
      //Replace http with https to avoid mixed content error
      fetchWasteData(pagination.next.replace(/^http:\/\//i, "https://"));
    }
  };

  useEffect(() => {
    if (selectedBin === undefined) {
      axiosInstance.get(`/get_waste/0/${tableDate}`,{
        params: {
          weight: sortDirectionWeight,
          time: sortDirectionTime,
        },
      }).then((response) => {
        if (response.data.count !== 0) {
          setTableData(response.data.results);

          if (response.data.count < 5) {
            setPageNumber(1);
          } else {
            setPageNumber(Math.ceil(response.data.count / 5));
          }

          setPagination(response.data);
        } else {
          setTableData(response.data.results);

          setPagination(null);
        }
      });
    }
    setSelectedBin(props.bin);

    axiosInstance
      .get(`/get_waste/${selectedBin.id}/${tableDate}`,
       {
        params: {
          weight: sortDirectionWeight,
          time: sortDirectionTime,
        },
      })
      .then((response) => {
        if (response.data.count !== 0) {
          setTableData(response.data.results);

          if (response.data.count < 5) {
            setPageNumber(1);
          } else {
            setPageNumber(Math.ceil(response.data.count / 5));
          }

          setPagination(response.data);
        } else {
          setTableData(response.data.results);

          setPagination(null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [tableDate, props, selectedBin, sortDirectionTime, sortDirectionWeight]);

  useEffect(() => {
    setCounter(1);
  }, [tableDate]);
  const fetchWasteData = (url) => {
    axiosInstance
      .get(url)
      .then((response) => {
        setTableData(response.data.results);
        setPagination(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <div className="lg:ml-0 md:ml-0 sm:-ml-[40px] xs:-ml-[40px]">
      <div className="">
        <div className="Waste-Items-Container">
        <div className="flex flex-row justify-between p-2">
        <h1 className="font-semibold text-xl">{language === "Dutch" ? "Afvalartikelen":  "Waste Items"}</h1>
          
          <div className="flex flex-col items-center cursor-pointer">
            <div className="dropdown flex flex-col ">
              <div>
                <div className="ml-auto pr-6 ">
                  <label className= "font-semibold" for="waste">{language === "Dutch" ? "Recent Toegevoegd" : "Recently Added"}</label>
                  <br />
                  <select
                    onChange={(e) => {
                      setTableDate(e.target.value);
                    }}
                    value={tableDate}
                    className=" text-sm p-2 border dark:bg-plaex-dark rounded-md"
                  >
                    <option value="" selected>
                    {language === "Dutch" ? "24 Uur Geleden" : "24 Hour Ago"}
                    </option>
                    <option value="week">{language === "Dutch" ? "Een week" : "A Week"}</option>
                    <option value="month">{language === "Dutch" ?  "Een Maand Geleden ":"A Month Ago"}</option>
                    <option value="year">{language === "Dutch" ?  "Een Jaar Geleden ": "A Year Ago"}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <section className="flex bg-white">
        <div className=" flex cursor-pointer right-0">
          <select className=" text-sm p-2 border dark:bg-plaex-dark rounded-md hover:text-plaex-green" onChange={(e) => handleFilterChange(e.target.value)}>
            <option value="" disabled selected className="flex justify-center items-center"> <FiFilter/>
            <h4>Sort</h4></option>
            <option value="weightAsc">Weight Ascending</option>
            <option value="weightDesc">Weight Descending</option>
            <option value="timeAsc">Date Ascending</option>
            <option value="timeDesc">Date Descending</option>
            <option value="clear">Clear Filter</option>
          </select>
          </div>

        </section>

        <div className=" bin-table-container dark:text-white Table-Container" style={{zIndex: -100}}>
          <table className="Table dark:text-white">
          <thead>
            <tr>
              <th class="Cell-Property header">{language=== "Dutch" ? "Nee": "No."}</th>
              <th class="Cell-Property header">Type</th>
              <th class="Cell-Property header">{language=== "Dutch" ? "Gewicht": "Weight"}</th>
              <th class="Cell-Property header">{language=== "Dutch" ? "Naam": "Name"}</th>
              <th class="Cell-Property header">{language==="Dutch" ? "Datum" :"Date"}</th>
              <th class="Cell-Property header">{language === "Dutch" ? "Tijd": "Time"}</th>
            </tr>
          </thead>
            <tbody>
              {tabledata &&
                tabledata.map((row) => (
                  <tr>
                    <td className="uppercase Cell-Property">
                      {row.bin.unique_identifier}
                    </td>
                    <td className="Cell-Property">{language === 'Dutch' ? translateWasteToDutch(row.waste_type) : row.waste_type === "rest" ? "Residual" : row.waste_type }</td>
                    <td className="Cell-Property">{Math.abs(row.waste_weight)} g</td>
                    <td className="Cell-Property">{row.sub_class_waste_type === "" ? "unidentified" : row.sub_class_waste_type}</td>
                    <td className="Cell-Property">
                      {row.time_collected.split("T")[0]}
                    </td>
                    <td className="Cell-Property">
                      {row.time_collected.split("T")[1].slice(0, -1)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {pagination && (
            <div className="flex flex-row gap-5 items-center justify-center mt-5 -ml-12">
              <button
                disabled={!pagination.previous}
                onClick={handlePrevClick}
                className="rounded-lg text-white p-2 text-[12px] cursor-pointer"
              >
                <AiFillLeftSquare className="text-gray-500 text-lg" />
              </button>
              <h4>
                {counter} of {pagenumber}
              </h4>
              <button
                disabled={!pagination.next}
                onClick={handleNextClick}
                className="rounded-lg text-white p-2 text-[12px] cursor-pointer"
              >
                <AiFillRightSquare className="text-green-500 text-lg" />
              </button>
            </div>
          )}

          {!pagination && (
            <div className="text-center flex items-center justify-center p-7">
              <div className="flex flex-col text-center items-center justify-center  text-gray-400">
                <AiFillDatabase className="text-2xl" />
                <h1 className="text-[13px] -ml-2">{language === "Dutch" ?  "Geen Gegevens":"No Data"}</h1>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
