import React from 'react'

const ImageDisplay = (props) => {
  const {link} = props
  return (

    <div>
      <div>
        <iframe 
          src={link} 
          style={{width: '100%', height: '600px', border: '0'}}
          title="Google Drive Folder"
        />
    </div>
    </div>
  )
}

export default ImageDisplay
