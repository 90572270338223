import PropTypes from "prop-types";
import React from "react";
import "./Button.css";

const Button = ({ text, onClick, disabled, className }) => {
  return (
    <button className={`Button ${className}`} onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
};

export default Button;
