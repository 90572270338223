import React from 'react'
import {MdEmail} from "react-icons/md"
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { LanguageContext } from 'context/LanguageContext';
import {useContext} from 'react'
const Contact = () => {
    
  
      const handleIconClick = () => {
        window.location.href = `mailto:hello@plaex.net`;
    }

    const {language} = useContext(LanguageContext)
    

  return (
    
    <>
        <div className='bg-plaex-bar rounded-lg h-10 w-10 fixed bottom-3 right-16 flex justify-center items-center cursor-pointer'
        data-tooltip-id='Contact-tip'
        data-tooltip-content={language === "Dutch"? "Neem Contact Met Ons Op":"Contact Us"}
        onClick={handleIconClick}
        >
        <MdEmail className='text-xl text-white hover:animate-bounce'/>
        </div>
        <ReactTooltip id='Contact-tip' />
    </>
  )
}

export default Contact
