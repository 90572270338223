import axiosInstance from "api/axios";
import React, { useEffect, useState, useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
import {
  AiFillDatabase,
  AiFillLeftSquare,
  AiFillRightSquare,
} from "react-icons/ai";
import "./Waste-Items.css";
import { FiFilter } from 'react-icons/fi';

const WasteItems = (props) => {
  const { id, value, wastevalue } = props;
  const [wasteitem, setWasteItem] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [pagenumber, setPageNumber] = useState(null);
  const { language } = useContext(LanguageContext);
  const [sortDirectionWeight, setSortDirectionWeight] = useState("default");
  const [sortDirectionTime, setSortDirectionTime] = useState("default");
  const [sortBy, setSortBy] = useState('weight');
  const [counter, setCounter] = useState(1);
  const [loading, setLoading] = useState(false); // Loading state

  // Handle sorting
  const handleFilterChange = (value) => {
    switch (value) {
      case 'weightAsc':
        setSortBy('weight');
        setSortDirectionWeight(true);
        break;
      case 'weightDesc':
        setSortBy('weight');
        setSortDirectionWeight(false);
        break;
      case 'timeAsc':
        setSortBy('time');
        setSortDirectionTime(true);
        break;
      case 'timeDesc':
        setSortBy('time');
        setSortDirectionTime(false);
        break;
      case 'clear':
        setSortBy('');
        setSortDirectionTime("default");
        setSortDirectionWeight("default");
        break;
    }
  };

  const handlePrevClick = () => {
    if (pagination && pagination.previous) {
      setCounter((prevCounter) => Math.max(prevCounter - 1, 1));
      fetchWasteData(pagination.previous.replace(/^http:\/\//i, "https://"));
    }
  };

  const handleNextClick = () => {
    if (pagination && pagination.next) {
      setCounter((prevCounter) => Math.min(prevCounter + 1, pagenumber));
      fetchWasteData(pagination.next.replace(/^http:\/\//i, "https://"));
    }
  };

  const fetchWasteData = (url) => {
    setLoading(true); // Start loading
    axiosInstance.get(url)
      .then((response) => {
        setWasteItem(response.data.results);
        setPagination(response.data);
        setLoading(false); // End loading
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // End loading on error
      });
  };

  // Clear previous data and reset pagination when `id` changes
  useEffect(() => {
    setWasteItem([]); // Clear previous data
    setPagination(null); // Reset pagination
    setCounter(1); // Reset counter
    setLoading(true); // Set loading to true

    axiosInstance
      .get("/wasteitem", {
        params: {
          id: id,
          value: value,
          wastevalue: wastevalue,
          weight: sortDirectionWeight,
          time: sortDirectionTime,
        },
      })
      .then((response) => {
        setLoading(false); // End loading
        if (response.data.count !== 0) {
          setWasteItem(response.data.results);
          setPageNumber(Math.ceil(response.data.count / 5));
          setPagination(response.data);
        } else {
          setWasteItem([]);
          setPagination(null);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // End loading on error
      });
  }, [id, value, wastevalue, sortDirectionTime, sortDirectionWeight]); // Listen for changes in `id` and other params

  const translateWasteToDutch = (text) => {
    const translations = {
      "organic": "Biologisch",
      "PMD": "PMD",
      "paper": "papier",
      "rest": "Resterend"
    };
    return translations[text] || text;
  }

  return (
    <div className="w-full">
      {/* Filter Section */}
      <div className="mb-4 flex justify-end">
        <select
          className="text-sm p-2 border rounded-md hover:text-plaex-green focus:outline-none focus:ring-2 focus:ring-green-500"
          onChange={(e) => handleFilterChange(e.target.value)}
        >
          <option value="" disabled selected>
            <FiFilter className="inline mr-2" />
            {language === "Dutch" ? "Sorteren" : "Sort"}
          </option>
          <option value="weightAsc">{language === "Dutch" ? "Gewicht Oplopend" : "Weight Ascending"}</option>
          <option value="weightDesc">{language === "Dutch" ? "Gewicht Aflopend" : "Weight Descending"}</option>
          <option value="timeAsc">{language === "Dutch" ? "Datum Oplopend" : "Date Ascending"}</option>
          <option value="timeDesc">{language === "Dutch" ? "Datum Aflopend" : "Date Descending"}</option>
          <option value="clear">{language === "Dutch" ? "Filter Wissen" : "Clear Filter"}</option>
        </select>
      </div>

      {/* Loading Indicator */}
      {loading ? (
        <div className="text-center p-7">
          <span className="text-gray-500">{language === "Dutch" ? "Bezig met laden..." : "Loading..."}</span>
        </div>
      ) : (
        <>
          {/* Table Section */}
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100 dark:bg-gray-700">
                  <th className="p-2 text-left">{language === "Dutch" ? "Nee" : "No."}</th>
                  <th className="p-2 text-left">Type</th>
                  <th className="p-2 text-left">{language === "Dutch" ? "Gewicht" : "Weight"}</th>
                  <th className="p-2 text-left">{language === "Dutch" ? "Naam" : "Name"}</th>
                  <th className="p-2 text-left">{language === "Dutch" ? "Datum" : "Date"}</th>
                  <th className="p-2 text-left">{language === "Dutch" ? "Tijd" : "Time"}</th>
                </tr>
              </thead>
              <tbody>
                {wasteitem && wasteitem.map((row) => (
                  <tr key={row.bin.unique_identifier} className="border-b dark:border-gray-600">
                    <td className="p-2 uppercase">{row.bin.unique_identifier}</td>
                    <td className="p-2">{language === 'Dutch' ? translateWasteToDutch(row.waste_type) : row.waste_type === "rest" ? "Residual" : row.waste_type}</td>
                    <td className="p-2">{Math.abs(row.waste_weight)}g</td>
                    <td className="p-2">{row.sub_class_waste_type === "" ? "unidentified" : row.sub_class_waste_type}</td>
                    <td className="p-2">{row.time_collected.split("T")[0]}</td>
                    <td className="p-2">{row.time_collected.split("T")[1].split('.')[0]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Section */}
          {pagination && (
            <div className="flex items-center justify-center mt-4 space-x-4">
              <button
                disabled={!pagination.previous}
                onClick={handlePrevClick}
                className="p-2 rounded-lg text-gray-500 disabled:opacity-50"
              >
                <AiFillLeftSquare className="text-2xl" />
              </button>
              <span className="text-sm">
                {counter} of {pagenumber}
              </span>
              <button
                disabled={!pagination.next}
                onClick={handleNextClick}
                className="p-2 rounded-lg text-green-500 disabled:opacity-50"
              >
                <AiFillRightSquare className="text-2xl" />
              </button>
            </div>
          )}

          {/* No Data Section */}
          {!pagination && wasteitem.length === 0 && (
            <div className="text-center p-7">
              <div className="flex flex-col items-center text-gray-400">
                <AiFillDatabase className="text-4xl mb-2" />
                <span className="text-sm">{language === "Dutch" ? "Geen Gegevens" : "No Data"}</span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WasteItems;
