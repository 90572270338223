import React, {useEffect, useState, useCallback} from 'react';
import axiosInstance from 'api/axios';
import Navbar from 'common/Components/NavBar/NavBar';
import BinTable from './Components/BinTable';
import FillLevelChart from './Components/FillLevelChart';
import WasteChart from './Components/WasteChart';
import TotalWasteCount from './Components/TotalWasteCount';
import Feedback from 'common/Components/Feedback/Feedback';
import Contact from 'common/Components/Contact/Contact';
import CustomModal from 'pages/Recommendation/Components/CustomModal';
import Recommendation from 'pages/Recommendation/Recommendation';
import TotalWeightCount from 'pages/WeightScale/Components/TotalWeightCount';
import './MainDashboard.css';
import WasteDashboard from 'pages/Waste-Dashboard/WasteDashboard';
import CO2Savings from 'pages/CO2-Savings/Savings';
import SubclassDisplay from 'pages/Subclass-Display/SubclassDisplay';
import Dashboard from 'pages/Waste-Dashboard/Dashboard';
import BubbleChart from "../Waste-Dashboard/Components/Waste-Bubblechart/BubbleChart";

const MainDashboard = () => {
    const [selectedBin, setSelectedBin] = useState();
    const [id, setId] = useState();
    const [counter, setCounter] = useState(0);
    const [bintype, setBinType] = useState(true);
    const [onoff, setOnOff] = useState({});
    const [tier, setTier] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const handleCloseModal = () => setModalIsOpen(false);

    useEffect(() => {
        axiosInstance
            .get('/get_bins')
            .then((response) => {
                setId(response.data[1].unique_identifier)
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handleClick = (bin) => {
        const {unique_identifier} = bin
        setId(unique_identifier);
        setSelectedBin(bin);
    };

    const wasteitem = 'organic';
    const selectedValue = useState("year");
    const dayvalue = useState("day");
    const datevalue = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    // Fetch user tier
    useEffect(() => {
        axiosInstance.get('/usertier')
            .then(response => setTier(response.data.tier))
            .catch(console.error);
    }, []);

    // Fetch bin data and update bin type
    useEffect(() => {
        axiosInstance.get('/get_bins')
            .then(response => {
                const bins = response.data;
                setBinType(!bins.some(bin => bin.is_garfill));
            })
            .catch(console.error);
    }, []);

    // Check bin status and set interval
    const checkBinStatus = () => {
        axiosInstance
            .get(`/isOnline`)
            .then((response) => {
                console.log(response.data)
                setOnOff(response.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (counter === 0) {
            checkBinStatus()
            setCounter(1)
        }
        const intervalCall = setInterval(() => {
            checkBinStatus()
        }, 15000);
        return () => {
            clearInterval(intervalCall);
        };
    },[]);

    // Handle modal open/close
    useEffect(() => {
        const timer = setTimeout(() => setModalIsOpen(true), 5000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`w-screen h-screen m-0 p-0 mt-0`}>
            <Navbar/>
            <div className={`w-full overflow-auto mb-6 ${bintype ? "md:ml-0" : "ml-0"}`}>
                <div className="maindashboard-content flex flex-col w-full">
                    <BinTable onBinClick={handleClick} onoff={onoff}/>
                </div>
            </div>

            <Dashboard id_={id} wasteitem_={wasteitem} selectedValue_={selectedValue} dayvalue_={dayvalue}
                       datevalue_={datevalue}/>

            <Feedback/>
            <Contact/>
        </div>
    );
};

export default MainDashboard;
