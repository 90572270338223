import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import axiosInstance from "api/axios";
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';

const categories = [
  { id: 'paper', label: 'Paper', color: '#0000FF' },
  { id: 'pmd', label: 'PMD', color: '#FFDB58' },
  { id: 'residual', label: 'Residual', color: '#FF0000' },
  { id: 'organic', label: 'Organic', color: '#008000' }
];

const BubbleChart = ({ id, dayvalue, language, message2, totalwasteproduced, colorwaste, percentagechange, TotalWasteWeight }) => {
  const [bubbleData, setBubbleData] = useState([]);
  const [totalWasteObject, setTotalWasteObjects] = useState(0);

  useEffect(() => {
    axiosInstance.get('/waste_composition', {
      params: { id, day: dayvalue }
    })
      .then(response => {
        const data = response.data;
        const [paper, pmd, residual, organic] = Object.values(data);

        setTotalWasteObjects(Number(paper) + Number(pmd) + Number(residual) + Number(organic));

        const newBubbleData = [
          { id: 'paper', label: 'Paper', value: Number(paper) },
          { id: 'pmd', label: 'PMD', value: Number(pmd) },
          { id: 'residual', label: 'Residual', value: Number(residual) },
          { id: 'organic', label: 'Organic', value: Number(organic) }
        ].sort((a, b) => b.value - a.value);

        setBubbleData(newBubbleData);
      })
      .catch(error => {
        console.error(error);
      });
  }, [dayvalue, id]);

  const minSize = 60;
  const maxSize = 200;

  const getSize = (value) => {
    const minValue = Math.min(...bubbleData.map(item => item.value));
    const maxValue = Math.max(...bubbleData.map(item => item.value));
    const scaledValue = (value - minValue) / (maxValue - minValue);
    return scaledValue * (maxSize - minSize) + minSize;
  };

  const getColor = (id) => {
    const category = categories.find((category) => category.id === id);
    return category ? category.color : '#000000';
  };

  return (
    <div className="w-full p-4">
      <h1 className="text-center text-green-700 mb-6">
        <div className="flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-4">
          <span className="text-sm">{language === "Dutch" ? message2 : "Subwaste Composition"}</span>
          <div className="flex items-center space-x-2">
            <span className="font-semibold text-sm sm:text-base">
              {totalWasteObject} <span className="font-light">Objects</span> | {TotalWasteWeight} <span className="font-light">KG</span>
            </span>
            <span className={`text-${colorwaste}-500 flex items-center text-xs sm:text-sm`}>
              {percentagechange}% {colorwaste === "red" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
            </span>
          </div>
        </div>
      </h1>

      {/* Bubble chart grid layout for better responsiveness */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 justify-center items-end">
        {bubbleData.map((item) => (
          <motion.div
            key={item.id}
            layout
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ duration: 0.5 }}
            className="m-2 flex flex-col items-center"
          >
            <motion.div
              className="rounded-full flex items-center justify-center text-white font-bold"
              style={{
                width: getSize(item.value),
                height: getSize(item.value),
                backgroundColor: getColor(item.id),
              }}
            >
              <span className="text-xs sm:text-sm md:text-base">{item.value}</span>
            </motion.div>
            <span className="mt-2 text-xs sm:text-sm">{item.label}</span>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default BubbleChart;
