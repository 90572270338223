import React, {useState, useEffect} from 'react';
import axiosInstance from "api/axios";
import {animate} from 'framer-motion';
import {
    FaLeaf,
    FaRecycle,
    FaRegFileAlt,
    FaTrash,
    FaExclamationCircle,
} from 'react-icons/fa';
import debounce from 'lodash/debounce';
import './BinTable.css';

const SkeletonLoader = () => (
    <div className="animate-pulse flex space-x-4">
        <div className="flex-1 bg-gray-300 h-6 rounded"/>
        <div className="flex-1 bg-gray-300 h-6 rounded"/>
        <div className="flex-1 bg-gray-300 h-6 rounded"/>
        <div className="flex-1 bg-gray-300 h-6 rounded"/>
    </div>
);

const renderAlertIcon = (fillLevel) => {
    const threshold = 80;
    return fillLevel > threshold ? (
        <FaExclamationCircle className="text-red-500 ml-1"/>
    ) : null;
};

const FillLevelBar = ({type, level, icon: Icon}) => (
    <div className="flex flex-col space-y-1 w-1/4 px-1">
        <div className="flex items-center justify-between">
            <div className="flex items-center">
                <Icon className="mr-1 w-3 h-3"/>
                <span className="text-xs truncate">{type}</span>
            </div>
            <span className="text-xs font-bold text-green-600">{level}%</span>
            {renderAlertIcon(level)}
        </div>
        <div className="w-full bg-gray-200 rounded-full h-1.5">
            <div
                className="bg-green-600 h-1.5 rounded-full"
                style={{width: `${level}%`}}
            ></div>
        </div>
    </div>
);

export default function Table({BinUniqueIdentifier}) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [count, setCount] = useState(0)

    const bins = [
        {
            id: 'J1',
            fillLevels: {organic: 0, pmd: 0, paper: 0, residual: 0},
        },
        {
            id: 'J3',
            fillLevels: {organic: 0, pmd: 0, paper: 0, residual: 0},
        },
        {
            id: 'J5',
            fillLevels: {organic: 0, pmd: 0, paper: 0, residual: 0},
        },
        {
            id: 'H1',
            fillLevels: {organic: 0, pmd: 0, paper: 0, residual: 0},
        },
        {
            id: 'J6',
            fillLevels: {organic: 0, pmd: 0, paper: 0, residual: 0},
        },
        {
            id: 'T1',
            fillLevels: {organic: 0, pmd: 0, paper: 0, residual: 0},
        },
        {
            id: 'X1',
            fillLevels: {organic: 0, pmd: 0, paper: 0, residual: 0},
        },
    ];

    class Bin {
        constructor(id) {
            this.id = id; // Set the bin id from response
            this.fillLevels = {organic: 0, pmd: 0, paper: 0, residual: 0}; // Default fill levels
        }

        // Optional: Custom toString for debugging
        toString() {
            return `Bin ID: ${this.id}, Fill Levels: { organic: ${this.fillLevels.organic}, pmd: ${this.fillLevels.pmd}, paper: ${this.fillLevels.paper}, residual: ${this.fillLevels.residual} }`;
        }
    }

    // Define the sound to play
    const playNotificationSound = () => {
        const audio = new Audio('/notification.mp3'); // Path to your sound file
        audio.play();
    }

    // Scroll to the top of the page function
    const scrollToTop = () => {

        animate(window.scrollY, 0, {
            duration: 0.8,
            onUpdate: (value) => window.scrollTo(0, value) // Animate the scroll position
        });
    };


    /**
     *
     *  useEffect(() => {
     *        setLoading(true);
     *         axiosInstance.get('/livechart')
     *             .then((response) => {
     *                 if (response.data) {
     *                     console.log("fill_levels", response.data)
     *                     setData(response.data);
     *                 }
     *                 setLoading(false);
     *                 if (isFirstLoad) {
     *                     setIsFirstLoad(false);
     *                 }
     *
     *             }).catch((error) => {
     *             setError(error);
     *             setLoading(false);
     *         })
     *
     *
     *
     *     }[isFirstLoad]
     */


    useEffect(() => {
        setLoading(true);
        axiosInstance.get('/bin_ids')
            .then((response) => {
                if (response.data) {
                    // Parse the string response to get the array of bin IDs
                    const binIds = JSON.parse(response.data);
                    console.log("fill_levels", binIds);

                    // Create Bin objects for each binId, with initial fillLevels
                    let bin_data = [];
                    for (let i = 0; i < binIds.length; i++) {
                        // Create a Bin for each id with initial fillLevels
                        bin_data.push(new Bin(binIds[i]));
                    }

                    // Update the state with the created bin data
                    setData(bin_data);
                }

                setLoading(false);
                if (isFirstLoad) {
                    setIsFirstLoad(false);
                }

            }).catch((error) => {
            setError(error);
            setLoading(false);
        });
    }, []);

    const fetchCachedLiveData = async () => {
        try {
            const response = await axiosInstance.get('/cached_livechart');

            if (response.data && response.data.length > 0) {
                setData(response.data);

                if ((count === 0)) {
                    //playNotificationSound();

                    // Scroll to the top of the page
                    //scrollToTop();
                    console.log("Cached Data", response.data)
                    setCount(1)

                }

            } else {
                console.log('No cached Data')
            }

        } catch (err) {
            console.error('Error fetching data:', err.message);
            setError(err.message);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (count === 0 && data === null) {
                fetchCachedLiveData();
            } else {
                clearInterval(interval); // Stop polling once conditions are met
            }
        }, 1000);

        return () => clearInterval(interval); // Clean up interval on component unmount
    }, [count, data]); // Dependencies ensure interval re-evaluates when count or data changes


    if (isFirstLoad && loading) return <SkeletonLoader/>;
    if (error) {
        console.error('Error fetching data:', error);
    }
    if (!data) return <div>No data available</div>;

    const binData = data.find(bin => bin.id === BinUniqueIdentifier);
    if (!binData) return null;

    return (
        <div className="flex -mx-1">
            <FillLevelBar type="Organic" level={binData.fillLevels.organic} icon={FaLeaf}/>
            <FillLevelBar type="PMD" level={binData.fillLevels.pmd} icon={FaRecycle}/>
            <FillLevelBar type="Paper" level={binData.fillLevels.paper} icon={FaRegFileAlt}/>
            <FillLevelBar type="Residual" level={binData.fillLevels.residual} icon={FaTrash}/>
        </div>
    );
}
