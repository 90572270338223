import {useContext} from "react";
import logo from "Assets/svg/plaexLogo.svg";
import whitelogo from "Assets/svg/plaexLogowhite.svg";
import English from "Assets/images/English.png";
import Dutch from "Assets/images/Dutch.png";
import axiosInstance from "api/axios";
import React, {useEffect, useState} from "react";
import {BiSolidChevronDown} from 'react-icons/bi';
import {LanguageContext} from "context/LanguageContext";
import {PiUploadSimpleLight} from "react-icons/pi";
import PropTypes from "prop-types";
import {Link, useNavigate} from "react-router-dom";

import {
    Dialog,
    Disclosure,
    DisclosureButton, DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
} from "@headlessui/react";

import {Bars3Icon, BellIcon, XMarkIcon} from "@heroicons/react/24/outline";

const Navbar = (props) => {
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [userLogo, setUserLogo] = useState("");
    const [theme, setTheme] = useState(localStorage.theme);
    const [parentState, setParentState] = useState(theme);
    const [langimage, setLangImage] = useState(localStorage.getItem('langimage') || English);
    const [displaydropdown, setDisplayDropDown] = useState(false);
    const [languagepreference, setLanguagePreference] = useState("English");
    const [isHovered, setIsHovered] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const {setLanguage} = useContext(LanguageContext);


    const [profiledata, setProfileData] = useState({
        user_id: null,
        email: "",
        first_name: "",
        last_name: "",
        user_profile: "",
        company: "",
        phone_number: "",
        kvk_number: "",
    });
    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState("");

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);
        setImageName(selectedImage.name);
    };

    const handlePost = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("user_profile", image);
        try {

            const csrfToken = document.cookie.split("; ")
                .find(row => row.startsWith("csrftoken="))
                ?.split("=")[1];

            const response = await axiosInstance.put("/update", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-CSRFToken": csrfToken,
                },
                withCredentials: true,
            });
            setProfileData({...profiledata, ...response.data});
            setShowModal(false);
            window.location.reload();
            //navigate('/');
        } catch (error) {
            console.error("Error updating profile:", error.response?.data || error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get("");

                let updatedUserProfile = response.data.user_profile;


                if (
                    !response.config.baseURL.startsWith("http://127.0.0.1") &&
                    !response.config.baseURL.startsWith("http://localhost")
                ) {
                    updatedUserProfile = updatedUserProfile.replace(
                        /^http:\/\//i,
                        "https://",
                    );
                }

                setProfileData({
                    ...profiledata,
                    ...response.data,
                    user_profile: updatedUserProfile,
                });
            } catch (error) {
                console.error("Error fetching profile data:", error);
            } finally {
                //setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        localStorage.setItem('langimage', langimage);
    }, [langimage]);

    useEffect(() => {
        axiosInstance
            .get("")
            .then((response) => {
                setEmail(response.data.email);
                setCompany(response.data.company);
                setUserLogo(response.data.user_profile);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        axiosInstance
            .get("/get_bins")
            .then((response) => {
                for (let i = 0; i < response.data.length; i++) {
                    const binObj = response.data[i];
                    if (binObj.is_garfill === false) {
                        let element = document.querySelector("#logout-btn");
                        element.style.display = "none";
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handleDropDownChange = () => {
        setDisplayDropDown(!displaydropdown);
    };

    const handleLogout = () => {
        try {
            axiosInstance.post("/user/logout", {
                withCredentials: true,
            });
            localStorage.removeItem("loggedin");
            window.location.href = "/login";
        } catch (e) {
            console.log("logout not working", e);
        }
    };

    const handleLanguageChange = (event) => {
        const language = event.target.getAttribute('value');
        if (language === "English") {
            setLangImage(English);
        } else {
            setLangImage(Dutch);
        }
        setLanguagePreference(language);
        setLanguage(language);
    };

    return (
        <Disclosure as="nav" className="bg-white shadow">
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        {/* Mobile menu button */}
                        <DisclosureButton
                            className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:bg-plaex-green">
                            <span className="absolute -inset-0.5"/>
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden"/>
                            <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block"/>
                        </DisclosureButton>
                    </div>
                    <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex flex-shrink-0 items-center">
                            <a href="/">
                                <img
                                    alt="PLAEX Technologies Logo"
                                    src={logo}
                                    className="h-8 w-auto"
                                />
                                <img
                                    alt="PLAEX Technologies Logo"
                                    src={whitelogo}
                                    className="hidden dark:block h-8 w-auto"
                                />
                            </a>

                        </div>
                        <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                            {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                            <a
                                href="/"
                                className="inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900"
                            >
                                Dashboard
                            </a>

                        </div>
                    </div>
                    {/* Start Here */}
                    <div
                        className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        <div
                            className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:bg-plaex-green focus:ring-offset-2"
                            onClick={handleDropDownChange}
                        >

                            <button className="flex flex-row items-center justify-center gap-x-1 p-2">
                                <img src={langimage} className="w-6 h-6"/>
                                <BiSolidChevronDown/>
                            </button>

                            {displaydropdown &&
                                <ul className="absolute top-[100%] z-50 bg-white shadow-lg rounded-lg p-2 pr-2">
                                    <li className="flex items-center justify-start gap-x-1 text-[13px] hover:bg-plaex-light-green p-2 pr-16 rounded-lg cursor-pointer"
                                        value="English" onClick={handleLanguageChange}>
                                        <img src={English} className="w-6 h-6"/>English
                                    </li>
                                    <li className="flex items-center justify-start gap-x-1 text-[13px] hover:bg-plaex-light-green p-2 pr-16 rounded-lg cursor-pointer"
                                        value="Dutch" onClick={handleLanguageChange}>
                                        <img src={Dutch} className="w-6 h-6"/>Dutch
                                    </li>
                                </ul>}
                        </div>

                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3">
                            <div>
                                <MenuButton
                                    className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <span className="absolute -inset-1.5"/>
                                    <span className="sr-only">Open user menu</span>
                                    <img
                                        alt=""
                                        src={userLogo}
                                        className="w-8 h-8 rounded-full"
                                    />
                                </MenuButton>
                            </div>
                            <MenuItems
                                transition
                                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                                <MenuItem>
                                    <Link to={"/profile"}
                                          className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-plaex-light-green">
                                        Your Profile
                                    </Link>
                                </MenuItem>

                                <MenuItem>
                                    <a onClick={handleLogout}
                                       className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-plaex-light-green">
                                        Sign out
                                    </a>
                                </MenuItem>
                            </MenuItems>
                        </Menu>
                    </div>
                </div>
            </div>

            <DisclosurePanel className="sm:hidden">
                <div className="space-y-1 pb-4 pt-2">
                    {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                    <DisclosureButton
                        as="a"
                        href="/"
                        className="block border-l-4 border-indigo-500 bg-plaex-green/50 py-2 pl-3 pr-4 text-base font-medium text-plaex-green"
                    >
                        Dashboard
                    </DisclosureButton>
                </div>
            </DisclosurePanel>
        </Disclosure>
    )
};


export default Navbar;
