import axiosInstance from "api/axios";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useEffect, useState } from "react";
import {MdOutlineArrowForward } from "react-icons/md";
import { Bar } from "react-chartjs-2";
import { AiFillDatabase } from "react-icons/ai";
import "../Components/FillLevelChart.css";
import {Link } from 'react-router-dom';

const FillLevelChart = (props) => {
  const { selectedBin } = props;
  const [waste, setWaste] = useState([]);

  useEffect(() => {
    ChartJS.register(
      BarElement,
      Tooltip,
      Legend,
      CategoryScale,
      LinearScale,
      ChartDataLabels
    );
  }, []);

  useEffect(() => {
    async function fetchFillLevel() {
      let data = null;
      if (selectedBin === undefined) {
        const response = await axiosInstance.get("/get_filllevel");
        data = response.data;
      } else {
        const response = await axiosInstance.get(
          `get_filllevel_selected/${selectedBin.id}`
        );
        data = response.data;
      }
      setWaste(data);
    }

    fetchFillLevel();
  }, [selectedBin]);

  if (waste.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center text-gray-300 ">
        <div className="flex flex-col justify-center items-center p-10">
          <h3 className="text-gray-400">No FillLevel Data</h3>
          <AiFillDatabase className="text-5xl" />
        </div>
      </div>
    );
  }

  const wasteData = {
    // labels: to be turned to days
    labels: waste.map((x) => x.label),
    datasets: [
      {
        label: "Waste Fill Level",
        data: waste.map((x) => x.fill_level),
        borderWidth: 1,
        backgroundColor: ["#004800"],
        datalabels: {
          color: "white",
        },
        borderRadius: 10,
        borderColor: [],
        barPercentage: 0.5,
        barThickness: 30,
        maxBarThickness: 40,
        minBarLength: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
          // drawOnChartArea: false,
        },

        // to hide yaxis
        ticks: {
          font: {
            size: 0,
          },

          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },

        border: {
          display: false,
        },
      },

      x: {
        grid: {
          display: false,
        },
        // to hide xaxis
        ticks: {
          maxRotation: 90,
          minRotation: 90,
          font: {
            size: 11,
            weight: 600,
          },
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },

        border: {
          display: false,
        },
      },
    },

    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        font: {
          size: 10.5,
        },
        formatter: (value) => {
          let percentage = value + "%";
          return percentage;
        },
        align: "start",
        anchor: "end",
      },
    },
  };

  // const barPattern = {
  //   id: "barPattern",
  //   beforeDatasetsDraw(chart, args, pluginOptions) {
  //     const {
  //       ctx,
  //       chartArea: { top, bottom, height },
  //       scales: { x, y },
  //     } = chart;

  //     ctx.save();
  //     chart.getDatasetMeta(0).data.forEach((dataPoint, index) => {
  //       ctx.fillRect(x.getPixelForValue(index) - 25, top, 50, height - 0.5);
  //       ctx.fillStyle = "#C9FFD5";
  //     });
  //   },
  // };

  return (
    <div className="">
      <main
        id="fillLevel"
        class="dark:text-white text-slate-900  sm:640px md:768px lg:1024px xl:1280px 2xl:1536px mb-16 mx-12"
      >
        <section className="topSection" class="flex justify-between mb-4 text-base ">
          <h2 class="text-lg font-semibold  pb-4">Average Fill Level</h2>
          {/* <Link to='/waste-dashboard'>
            <div class="seeFillAll flex justify-between items-center gap-x-2.5 cursor-pointer text-sm ">
              <p>See All</p>
              <MdOutlineArrowForward />
            </div>
          </Link> */}
        </section>

        <div className="fillChart">
          <section id="fillLevelChart">
            <Bar
              data={wasteData}
              plugins={[ChartDataLabels]}
              options={options}

              // height={250}
              // plugins={[barPattern]}
              // width={"1000%"}
            />
          </section>
          <section id="fillLevelCapacity" class="text-xs font-semibold">
            <div className="level">
              <div id="wasteFillLevel"></div>
              <p>Waste Fill Level</p>
            </div>
            {/* <div className="level">
            <div id="binLevel"></div>
            <p>Bin Level</p>
          </div> */}
          </section>
        </div>
      </main>
    </div>
  );
};

export default FillLevelChart;
