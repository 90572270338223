import logo from "Assets/images/logo_white.png";
import back_video from "Assets/videos/Login_background.mp4";
import axiosInstance from "api/axios";
import { useEffect, useRef, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Login.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { LanguageContext } from "context/LanguageContext";
import {BiSolidChevronDown} from 'react-icons/bi'
import English from "Assets/images/English.png"
import Dutch from "Assets/images/Dutch.png"


function LoginPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const userRef = useRef();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");
  const [openeye, setOpeneye] = useState(true);
  const [eyevisible,seteyevisible] = useState(false);
  const { setLanguage } = useContext(LanguageContext);
  const [langimage, setLangImage] = useState(localStorage.getItem('langimage') || English);
  const [displaydropdown, setDisplayDropDown] = useState(false)
  const [languagepreference, setLanguagePreference] = useState("English")

  useEffect(() => {
    localStorage.setItem('langimage', langimage);
  }, [langimage]);

  //focus every time component load
  useEffect(() => {
    userRef.current.focus();
  }, []);

  const handleDropDownChange  = ()=>{
    setDisplayDropDown(!displaydropdown)
  }


  const handleLanguageChange = (event) => {
    const language = event.target.getAttribute('value')
    if (language == "English"){
      setLangImage(English)
    }else{
      setLangImage(Dutch)
    }
    setLanguagePreference(language)
    setLanguage(language)
  }

  //remove Err if user change values
  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = {
      email: email,
      password: password,
    };

    try {
      await axiosInstance.post("user/login", user, {
        withCredentials: true,
      });

      setEmail("");
      setPassword("");
      window.location.href = "/";

      localStorage.setItem("loggedin", "true");

    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        console.log(err.response);
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  const handleSetEyeStateOpen = () => {
    setOpeneye(false);
    seteyevisible(true);
  };

  const handleSetEyeStateClose = ()=>{
    setOpeneye(true);
    seteyevisible(false);
  };

  return (
    <div className="relative h-screen">
      <div className="absolute inset-0 z-0">
        <video className="w-full h-full object-cover" autoPlay muted loop>
          <source src={back_video} type="video/mp4" />
        </video>
      </div>
      <div className="relative z-10 flex">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 flex items-center justify-center h-screen">
          <div className="mx-auto max-w-lg">
            <div className="mx-auto w-1/6 pb-5 flex justify-center items-center">
              
                      <div className="relative z-50 bg-white rounded-sm -ml-20" onClick={handleDropDownChange}>
          <button className="flex flex-row items-center justify-center gap-x-1 p-2">
            <img src={langimage} className="w-6 h-6" />
            <BiSolidChevronDown />
          </button>

          {displaydropdown && (
            <ul className=" absolute top-[100%] shadow-lg rounded-lg p-2 pr-2 bg-white ">
              <li
                className="flex items-center justify-start gap-x-1 text-[13px] hover:bg-plaex-light-green p-2 pr-16 rounded-lg cursor-pointer  text-black hover:text-black"
                value="English"
                onClick={handleLanguageChange}
              >
                <img src={English} className="w-6 h-6" />
                English
              </li>
              <li
                className="flex items-center justify-start gap-x-1 text-[13px] hover:bg-plaex-light-green p-2 pr-16 rounded-lg cursor-pointer text-black hover:text-black"
                value="Dutch"
                onClick={handleLanguageChange}
              >
                <img src={Dutch} className="w-6 h-6" />
                Dutch
              </li>
            </ul>
          )}
        </div>
              <img src={logo} alt="logo" />
            </div>
            <h1 className="text-center text-2xl font-bold text-green-600 sm:text-3xl">
              {langimage === Dutch ? "Aan De Slag Met Garby" : "Get started today with Garby"}
            </h1>

            <p className="mx-auto mt-4 max-w-md text-center text-white">
            {langimage === Dutch ? "Doe Met Ons Mee in Onze Missie Om De Wereld Een Schonere en Gezondere Plek Te Maken" : "Join us in our mission to make the world a cleaner and healthier place!"}
            </p>
            {/* Error message*/}
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscrean"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <form
              className="mt-6 mb-0 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8"
              onSubmit={handleSubmit}
            >
              <p className="text-center font-medium text-green-500 text-2xl">
                {langimage === Dutch ? "Meld u aan bij uw account" : "Sign in to your account"}
              </p>

              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>

                <div className="relative">
                  <input
                    type="email"
                    id="email"
                    ref={userRef}
                    className="w-full rounded-lg border-2 p-4 pr-12 text-sm shadow-sm focus:border-green-500 focus:outline-none"
                    placeholder={langimage === Dutch ? "Voer E-mail":"Enter email" }
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />

                  <span className="absolute inset-y-0 right-0 grid place-content-center px-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                      />
                    </svg>
                  </span>
                </div>
              </div>

              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>

                <div className="relative">
                  <input
                    type={eyevisible ? "text" : "password"}
                    id="password"
                    className="w-full rounded-lg border-2 p-4 pr-12 text-sm shadow-sm focus:border-green-500 focus:outline-none"
                    placeholder={langimage === Dutch ? "Voer Wachtwoord" : "Enter Password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />

                  <span className="absolute inset-y-0  cursor-pointer right-0 grid place-content-center px-4">
                    {
                      openeye === true ? <AiOutlineEye className="h-5 w-5 text-gray-400 hover:text-plaex_eye_hide_unhide focus:text-plaex_eye_hide_unhide active:text-plaex_eye_hide_unhide" onClick={()=>handleSetEyeStateOpen()}/>
                      :
                      <AiOutlineEyeInvisible className="h-5 w-5 text-gray-400 hover:text-plaex_eye_hide_unhide focus:text-plaex_eye_hide_unhide active:text-plaex_eye_hide_unhide" onClick={()=>handleSetEyeStateClose()}/>
                    }
                  </span>
                </div>
              </div>

              <button
                type="submit"
                className="block w-full rounded-lg bg-green-600 px-5 py-3 text-sm font-medium text-white hover:bg-green-400 hover:text-gray-800"
              >
                {langimage === Dutch ?"Aanmelden" : "Sign in"}
              </button>
              <div className="flex">
                <a href="/password/email">
                  <p className=" text-green-600 pt-2 underline">
                  {langimage === Dutch ? "Wachtwoord Vergeten?": "Forgot Password?"}
                  </p>
                </a>
                <a href="/signup">
                  <p className="ml-28 sm:ml-56 text-green-600 pt-2 underline whitespace-nowrap	 ">
                    {langimage === Dutch ? "Inschrijven": "Sign Up"}
                  </p>
                </a>
              </div>
            </form>
          </div>
        </div>



      </div>
    </div>
  );
}

export default LoginPage;
